<template>
    
    <div class="wrap">

        <div @click="close_fast_credit()" class="back"></div>

        <div class="inputs">

            <h2 @click="close_fast_credit()">
                {{ lang.trans_150 }}
                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg>
            </h2>

            <p>
               {{ lang.trans_154 }} {{ game.team.fast_credit_interest }}%.
            </p>

            <p>
                {{ lang.trans_155 }}
            </p>

            <div class="wrap">

                <InputRange 
                    name="Сумма кредита"  
                    v-model:model="loan_amount" 
                    placeholder="Сумма кредита" 
                    :max="game.team.fast_credit_limit"
                    :step=10>
                </InputRange>

            </div>

            <div @click="credit_order()" class="button">
                {{ lang.trans_156 }}
            </div>

            <div class="bottom">

                <div class="name">{{ lang.trans_157 }}</div>

                <div class="result">
                    
                    <div class="wrap">

                        <div class="name">
                            {{ lang.trans_158 }}
                        </div>

                        <div class="center"></div>

                        <div class="price">
                            {{ format_number(game.team.fast_credit) }}
                        </div>

                    </div>

                    <div class="wrap">

                        <div class="name">
                            {{ lang.trans_54 }}
                        </div>

                        <div class="center"></div>

                        <div class="price">
                            {{ game.team.fast_credit_interest }}%
                        </div>
                        
                    </div>

                    <div class="wrap">

                        <div class="name">
                            {{ lang.trans_159 }}
                        </div>

                        <div class="center"></div>

                        <div class="price">
                            {{ format_number(game.team.fast_credit_limit) }}
                        </div>
                        
                    </div>

                    <div class="wrap">

                        <div class="name">
                            {{ lang.trans_160 }}
                        </div>

                        <div class="center"></div>

                        <div class="price">
                            {{ format_number(game.team.fast_credit_accrued_interest) }}
                        </div>
                        
                    </div>

                    <div class="wrap">

                        <div class="name">
                            {{ lang.trans_161 }}
                        </div>

                        <div class="center"></div>

                        <div class="price">
                            {{ format_number(game.team.fast_credit_limit - game.team.fast_credit) }}
                        </div>
                        
                    </div>


                </div>
            </div>

        </div>
    </div>
</template>

<script>


import { onMounted, watch, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { get_lang_name, format_number } from "@/api/funcs";


import InputRange  from '@/components/form/InputRange.vue'

export default {

    components: {
        InputRange,
    },

    setup(){

        const route         = useRoute()
        const store         = useStore()
        const domain        = computed(() => store.getters.domain)
        const cookie        = computed(() => store.getters.cookie)
        const game          = computed(() => store.getters.game)
        const lang          = computed(() => store.getters.lang)
        const loan_amount   = ref(0)

        onMounted(() => loan_amount.value = game.value.team.fast_credit)
        watch(game, () => loan_amount.value = game.value.team.fast_credit)


        async function credit_order(){

            if(game.value.tact && game.value.tact.status != "Активен"){
                store.dispatch('update_err_by_code', 112); return
            }

            store.dispatch('global_block_window', true)
            
            const response = await fetch(`${domain.value}/api/teams/fast-credit`, {
                method: 'POST',
                headers: { 
                    'Content-Type':'application/json',
                    "Authorization": cookie.value,

                },
                body: JSON.stringify({
                    team_id: game.value.team.id,
                    loan_amount: loan_amount.value,
                })
                
            })

            const body = await response.json()
                

            console.log(body.error)

            store.dispatch('update_err_by_code', body.error)

            await store.dispatch('get_me_game', route.params.id)

            store.dispatch('global_block_window', false)

        }


        async function close_fast_credit() {
            store.dispatch('fast_credit', false)
        }

        

        return {
            domain,
            lang,
            game,
            get_lang_name,
            format_number,
            close_fast_credit,

            loan_amount,
            credit_order,

        }
    }
}
</script>
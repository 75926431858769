<template>
    <div class="wrap">

        <svg @click="event(team)" :class="[{active: team.is_open}]" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6367 12.5852L19.7435 2.82151C19.9106 2.61269 19.9991 2.37593 20 2.13479C20.0009 1.89365 19.9143 1.65654 19.7488 1.44705C19.5833 1.23755 19.3446 1.06297 19.0565 0.940682C18.7685 0.818392 18.441 0.75265 18.1068 0.75L1.8932 0.749998C1.55897 0.752649 1.23154 0.81839 0.94346 0.940681C0.655383 1.06297 0.416713 1.23755 0.251193 1.44704C0.0856735 1.65654 -0.00092774 1.89365 8.74416e-06 2.13479C0.000945228 2.37593 0.0893832 2.61268 0.256528 2.82151L8.36333 12.5852C8.53395 12.7882 8.7742 12.9559 9.06088 13.0724C9.34756 13.1888 9.671 13.25 10 13.25C10.329 13.25 10.6524 13.1888 10.9391 13.0724C11.2258 12.9559 11.466 12.7882 11.6367 12.5852Z" fill="#0944AA"/>
        </svg>

        <div @click="event(team)" class="name">
            {{ team.name }} - ({{ team.users ? team.users.length : 0 }})
        </div>

        <div v-if="!is_command(team.users)" @click="add_self" class="wrap green">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 3.5H19C19.5304 3.5 20.0391 3.71071 20.4142 4.08579C20.7893 4.46086 21 4.96957 21 5.5V19.5C21 20.0304 20.7893 20.5391 20.4142 20.9142C20.0391 21.2893 19.5304 21.5 19 21.5H15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10 17.5L15 12.5L10 7.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 12.5H3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <div @click="team_update_pop_up = true" class="wrap blue">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 4.5H4C3.46957 4.5 2.96086 4.71071 2.58579 5.08579C2.21071 5.46086 2 5.96957 2 6.5V20.5C2 21.0304 2.21071 21.5391 2.58579 21.9142C2.96086 22.2893 3.46957 22.5 4 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V13.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.5 2.99998C18.8978 2.60216 19.4374 2.37866 20 2.37866C20.5626 2.37866 21.1022 2.60216 21.5 2.99998C21.8978 3.39781 22.1213 3.93737 22.1213 4.49998C22.1213 5.06259 21.8978 5.60216 21.5 5.99998L12 15.5L8 16.5L9 12.5L18.5 2.99998Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>

        <update-name v-if="team_update_pop_up" :team="team" :close="team_update_pop_up_close"></update-name>


        <div @click="copy_registrtion_link" class="wrap blue">
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 13.5C10.4295 14.0741 10.9774 14.5491 11.6066 14.8929C12.2357 15.2367 12.9315 15.4411 13.6467 15.4923C14.3618 15.5435 15.0796 15.4403 15.7513 15.1897C16.4231 14.9392 17.0331 14.547 17.54 14.04L20.54 11.04C21.4508 10.097 21.9548 8.83394 21.9434 7.52296C21.932 6.21198 21.4061 4.95791 20.4791 4.03087C19.5521 3.10383 18.298 2.57799 16.987 2.5666C15.676 2.5552 14.413 3.05918 13.47 3.96997L11.75 5.67997" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.0002 11.5C13.5707 10.9259 13.0228 10.4508 12.3936 10.107C11.7645 9.76328 11.0687 9.55886 10.3535 9.50765C9.63841 9.45643 8.92061 9.55961 8.24885 9.8102C7.5771 10.0608 6.96709 10.4529 6.4602 10.96L3.4602 13.96C2.54941 14.903 2.04544 16.166 2.05683 17.477C2.06822 18.788 2.59407 20.042 3.52111 20.9691C4.44815 21.8961 5.70221 22.422 7.01319 22.4334C8.32418 22.4447 9.58719 21.9408 10.5302 21.03L12.2402 19.32" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>



    </div>
</template>

<script>

import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import UpdateName from "@/components/operator/teams/team/UpdateName.vue";

export default {

    components: {
        'update-name': UpdateName,
    },

    props: {
        team: Object,
        event: Function,
    },

    setup(props){

        const route     = useRoute()
        const store     = useStore()
        const template  = computed(() => store.getters.template)  
        const user      = computed(() => store.getters.user)

        const team_update_pop_up = ref(false)
        function team_update_pop_up_close(){
            team_update_pop_up.value = false
        }

        function is_command(users){
            if(users != undefined){
                return users.filter((o) => o.id == user.value.id).length > 0 ? true : false
            }

            return false
        }

        async function add_self() {

            store.dispatch('global_block_window', true)

            await store.dispatch('add_me_team', props.team.id)

            await store.dispatch('get_operator_teams', route.params.id)

            store.dispatch('global_block_window', false)
        }

        async function copy_registrtion_link() {

            if (!navigator.clipboard) {
                console.log("Fail copy link!"); return
            }

            await navigator.clipboard.writeText(`${location.origin}/${template.value.name}/registration/${route.params.id}/${props.team.id}`)

            store.dispatch('update_err_by_code', 285)
        }
        
        return {
            team_update_pop_up,
            team_update_pop_up_close,
            is_command,
            add_self,
            copy_registrtion_link,
        }

    }
}

</script>

<style scoped>

.operator.teams > .bottom > .team > .wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.operator.teams > .bottom > .team > .wrap > svg {
    transition: transform 250ms ease 0s;
    min-width: 20px;
    
}

.operator.teams > .bottom > .team > .wrap > svg.active {
    transform: rotate(180deg);
}

.operator.teams > .bottom > .team > .wrap > .name {
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.02em;
    color: #0944AA;
    flex-grow: 1;
    margin-left: 10px;
}


.operator.teams > .bottom > .team > .wrap > .wrap {
    border-radius: 4px;
    display: flex;
    padding: 5px;
    margin-left: 5px;
    cursor: pointer;
}

.operator.teams > .bottom > .team > .wrap > .wrap.green {
    background: linear-gradient(229.73deg, #00A76B -19.09%, #50FFAB 131.11%);
}

.operator.teams > .bottom > .team > .wrap > .wrap.blue {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
}

</style>
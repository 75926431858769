<template>

    <div v-if="!is_auth" class="login-in">

        <div class="form">

            <h2>Регистрация игрока в команду {{ team.name }}</h2>

            <div :class="['input', {red: err.is}]">
                <img :src="`${domain}/static/img/login_in_icon_2.png`"/>
                <input v-model="form.name" type="text" :placeholder="lang.trans_6" />
                <svg v-if="err.is" xmlns="http://www.w3.org/2000/svg" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#a70000" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c-.006 5.52-4.48 9.994-10 10Zm-1-7v2h2v-2h-2Zm0-8v6h2V7h-2Z"/></svg>
            </div>

            <div class="input">
                <img :src="`${domain}/static/img/login_in_icon_3.png`"/>
                <input v-model="form.passw" @keyup.enter="registration" type="password" :placeholder="lang.trans_7"/>
            </div>

            <div v-if="err.is" class="error">
                {{ err.name }}
            </div>

            <div @click='registration' class="button">{{ lang.trans_226 }}</div>

        </div>

    </div>

    <div v-else class="not-found">
        <div class="name">
            Вы уже авторизованы!
        </div>
        <div class="desc">
            Выйдите из учетной записи, если хотите пройти регистрацию заново.
        </div>
        <router-link :to="{ name: `cabinet` }" class="btn">
            ВЕРНУТЬСЯ В КАБИНЕТ
        </router-link>
    </div>

</template>

<script>

import { computed, onMounted, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import { set_cookie } from "@/api/Cookie";
import { requests } from "@/api/funcs";

export default {
  setup(){

    const router        = useRouter()
    const route         = useRoute()
    const store         = useStore()
    const domain        = computed(() => store.getters.domain)
    const lang          = computed(() => store.getters.lang)
    const user          = computed(() => store.getters.user)
    const is_auth		= computed(() => store.getters.is_auth)
    const team          = computed(() => store.getters.team)
    const err           = ref({is: false, name: ''})

    const form = ref({
        name: '',
        passw: '',
    })



    onMounted(async() => await store.dispatch('get_team', route.params.t_id))
    watch(user, () => store.dispatch('select_default_template', route.params.template))



    async function registration() {

        const [ ok, code ] = await requests(`${domain.value}/api/users/games/${route.params.id}/teams/${route.params.t_id}`, 'POST', '', form.value)
        
        console.log(ok, code);

        if(ok){

            for (const [key, val] of Object.entries(lang.value)) {

                if(key.includes(code)){
                    err.value.is = true
                    err.value.name = val
                    break
                }
            }
            
            store.dispatch('global_block_window', false); return
        }
    
        await auth()
    
    }

    async function auth() {

        const response = await fetch(`${domain.value}/api/users/login`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: form.value.name, 
                passw: form.value.passw,
            })
            
        })

        if(!response.ok){
            err.value = true; return
        }

        const body = await response.json()

        //устанавливаем куки в браузер
        set_cookie("token", body.token)

        //устанавливаем куки в хранилище
        await store.dispatch('update_cookie', `Bearer ${body.token}`)

        //обновляем юзера
        await store.dispatch('get_user')

        //забираем языки
        await store.dispatch('get_langs')

        //забираем шаблоны
        await store.dispatch('get_templates')

        //устанавливаем нужный язык
        await store.dispatch('set_lang', is_auth.value ? user.value.lang_id : false)

        //устанавливаем нужный шаблон
        await store.dispatch('set_template', is_auth.value ? user.value.template_id : route.params.template)

        //переходим в кабинет
        router.push({ name: 'cabinet' })
            
    }

    return {
      domain,
      err,
      registration,
      lang,
      team,
      form,
      is_auth,
    }
    

  }
}
</script>

<style scoped>

.not-found {
    background: #FFFFFF;
    border-radius: 15px;
    max-width: 320px;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found > .name {
    font-weight: 500;
    font-size: 32px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0944AA;
}

.not-found > .desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #0944AA;
    margin-top: 20px;
}

.not-found > a.btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
    border-radius: 4px;
    padding: 10px 20px;
    max-width: 220px;
    text-align: center;
    margin-top: 20px;
    text-decoration: none;
}

</style>

<template>
    <div class="textarea text">

        <label>{{ name }}</label>

        <div class="wrap">

            <textarea @input="$emit('update:model', test($event.target))" :value="model" :placeholder="placeholder" @keyup.enter="event()"></textarea>

            <div class="length">{{ model.length }}/{{ max }}</div>

        </div>

    </div>
</template>

<script>

export default {

    props: {
        name: String,
        model: String,
        max: Number,
        placeholder: String,
        event: Function,
    },

    emits: ["update:model"],
    
	setup(props){

        function test(target){
            
            let result = target.value

            if(result.length > props.max){

                result = result.substring(0, props.max)

            }

            target.value = result
            
            return result
        }

        return { 
            test,
        }
    }
}

</script>

<style scoped>

.textarea.text {
    position: relative;
    margin-bottom: 1.25em;
    border-radius: 5px;
}

.textarea.text > label {
    left: 0.8em;
    right: auto;
    position: absolute;
    background: white;
    color: #0944AA;
    font-size: .85em;
    top: -0.6em;
    padding: 0 0.35em;
}

.textarea.text > .wrap {
    border: 1px solid #0944AA;
    border-radius: 8px;
    padding: .8em .95em;
    display: flex;
    align-items: center;
}

.textarea.text > .wrap > textarea {
    max-width: 100%;
    margin: 0;
    border: none;
    outline: none;
    width: 100%;
    font-size: .9em;
    height: 100px;
}

.textarea.text > .wrap > .length {
    font-weight: 400;
    font-size: .9em;
    color: #0944AA;
    opacity: 0.5;
}

.textarea.text:last-of-type {
    margin-bottom: 0;
}

</style>

<template>
    <div class="input range">
        <label>{{ name }}</label>
        <div class="wrap">
            <div class="wrap">
                <transition name="bounce" mode="out-in">
                    <input
                        type='text'
                        @input="validateAndEmit($event.target)"
                        :value="model"
                        :placeholder="placeholder"
                        @keyup.enter="event()"
                    />
                </transition>

                <div class="length">0 — {{ max }}</div>

            </div>
            <input type="range" min="0" :max="max" @input="validateAndEmit($event.target)" :value="model" :step="step">
        </div>
    </div>
</template>
  
<script>
export default {

    // Задаем входные параметры
    props: {

        // Имя, будет отображаться в элементе label. Обязательное поле.
        name: {
            type: String,
            required: true,
        },

        // Число, отображаемое и изменяемое в элементах ввода (текстовом поле и поле диапазона). Обязательное поле.
        model: {
            type: Number,
            required: true,
        },

        // Максимальное значение для элементов ввода. Обязательное поле.
        max: {
            type: Number,
            required: true,
        },

        // Строка, отображаемая в текстовом поле ввода, когда оно пусто. Обязательное поле.
        placeholder: {
            type: String,
            required: true,
        },

        // Величина шага изменения значения в поле диапазона. По умолчанию равно 1
        step: {
            type: Number,
            default: 1,
        },

        // Функция, вызываемая при каждом обновлении модели. Необязательное поле.
        event: Function,

    },

    // Сообщаем Vue, что мы хотим вызывать событие для обновления model
    emits: ["update:model"],

    setup(props, ctx){

        // Функция для проверки значения ввода и генерации события обновления
        async function validateAndEmit(target) {

            // Проверяем и корректируем значение ввода
            let result = parseInt(target.value, 10); 
            result = isNaN(result) ? 0 : Math.min(Math.max(0, result), props.max);

            // Устанавливаем корректное значение в элемент ввода
            target.value = result;

            // Генерируем событие обновления модели с новым значением
            ctx.emit('update:model', result);

            // Вызываем дополнительное пользовательское событие (если оно определено)
            if (props.event) {
                await props.event();
            }
        }

        // Возвращаем функцию в качестве части открытого API компонента
        return {
            validateAndEmit,
        }
    }
}
</script>
  
<style lang="sass" scoped>
  
$color-blue: #0944AA
$color-dark-blue: #2364D5
$color-grey: #a1a1a1
$transition-time: 250ms

=transition-effect
  transition: all $transition-time

.input
  &.range
    position: relative
    margin-bottom: 1.25em
    border-radius: 5px

    > label
      left: 0.8em
      right: auto
      position: absolute
      background: white
      color: $color-blue
      font-size: .85em
      top: -0.6em
      padding: 0 0.35em
      z-index: 1

    > .wrap
      border: 1px solid $color-blue
      border-radius: 8px
      padding: .8em 0 0
      position: relative

      > input[type="range"]
        width: 100%
        padding: 0
        margin: 0
        position: absolute
        bottom: -5px

      > .wrap
        margin: 0 1.25em .95em
        display: flex
        align-items: center

        > input[type="text"]
          max-width: 100%
          margin: 0
          border: none
          outline: none
          width: 100%
          font-size: .9em

        > .length
          font-weight: 400
          font-size: .9em
          color: $color-blue
          opacity: 0.5
          min-width: 100px
          display: flex
          align-items: center
          justify-content: flex-end

      > .liner
        position: relative
        width: 100%
        margin-left: -1px
        margin-bottom: -1px

        .back
          height: .5em
          background: $color-grey
          border-radius: 0 0 8px 8px

        .strip
          height: .5em
          background: $color-dark-blue
          position: absolute
          border-radius: 0 0 8px 8px
          top: 0
          @include transition-effect

        .cursor
          height: 1em
          width: 1em
          background: white
          position: absolute
          border-radius: 15px
          top: -0.25em
          box-shadow: 0 0 5px grey
          cursor: pointer
          @include transition-effect

.input
  &.text:last-of-type
    margin-bottom: 0

</style>

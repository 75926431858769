<template>
    <div class="rules">

        <div @click="close()" class="back"></div>

        <div class="wrap">

            <div @click="close()" class="name">

                <div class="name">
                    {{ lang.trans_460 }}
                </div>

                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg>
            
            </div>

            <div class="desc">

                <div class="wrap">

                    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.6145 11.8102L20.5833 9.9248V4.27376C20.5833 3.49251 20.0989 2.7946 19.3645 2.51855L14.1562 0.56543C13.7343 0.403971 13.2656 0.403971 12.8385 0.56543L7.63017 2.51855C6.89579 2.7946 6.41142 3.49251 6.41142 4.27376V9.9248L1.38017 11.8102C0.651001 12.0863 0.166626 12.7842 0.166626 13.5654V19.2998C0.166626 20.0081 0.567668 20.6592 1.20308 20.9769L6.41142 23.5811C6.93746 23.8467 7.56246 23.8467 8.0885 23.5811L13.5 20.8727L18.9114 23.5811C19.4375 23.8467 20.0625 23.8467 20.5885 23.5811L25.7968 20.9769C26.4323 20.6592 26.8333 20.0081 26.8333 19.2998V13.5654C26.8333 12.7842 26.3489 12.0863 25.6145 11.8102ZM18.8125 9.96647L14.3854 11.6279V8.07585L18.8125 6.14876V9.96647ZM8.18746 4.20085L13.5 2.21126L18.8125 4.20085V4.2321L13.5 6.38835L8.18746 4.2321V4.20085ZM12.5625 19.3623L8.13538 21.5758V17.4561L12.5625 15.4352V19.3623ZM12.5625 13.529L7.24996 15.6852L1.93746 13.529V13.4977L7.24996 11.5081L12.5625 13.4977V13.529ZM25.0625 19.3623L20.6354 21.5758V17.4561L25.0625 15.4352V19.3623ZM25.0625 13.529L19.75 15.6852L14.4375 13.529V13.4977L19.75 11.5081L25.0625 13.4977V13.529Z" fill="currentColor"/>
                    </svg>

                    <div class="name">
                        {{ lang.trans_255 }}
                    </div>

                </div>

                <div class="wrap center">

                    <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5497 7.02771L10.9586 6.3335L6.57104 8.25081L8.16216 8.94682L12.5497 7.02771Z" fill="currentColor"/>
                        <path d="M25.5043 6.03026L13.5 0.777832L1.5 6.03026L4.35688 7.28003L10.6022 4.54849C10.7143 4.49955 10.8354 4.47428 10.9578 4.47428C11.0801 4.47428 11.2012 4.49955 11.3133 4.54849L15.1266 6.20804C15.2849 6.27735 15.4195 6.39127 15.514 6.53587C15.6086 6.68049 15.6589 6.8495 15.6589 7.02227C15.6589 7.19503 15.6086 7.36405 15.514 7.50865C15.4195 7.65325 15.2849 7.76717 15.1266 7.83648L10.38 9.91737L13.5 11.2827L25.5043 6.03026Z" fill="currentColor"/>
                        <path d="M0.166626 7.38965V23.2633L12.611 29.594V12.8341L9.0555 11.275V15.8083C9.05557 15.9598 9.01689 16.1089 8.94316 16.2412C8.86943 16.3737 8.76307 16.4849 8.63419 16.5647C8.50532 16.6444 8.35821 16.6898 8.20683 16.6967C8.05544 16.7035 7.90482 16.6716 7.76927 16.6038L4.21372 14.8261C4.06597 14.7522 3.94169 14.6386 3.85488 14.4982C3.76805 14.3576 3.72209 14.1957 3.72217 14.0306V8.9452L0.166626 7.38965Z" fill="currentColor"/>
                        <path d="M5.49988 13.4809L7.27765 14.3697V10.5004L5.49988 9.72266V13.4809Z" fill="currentColor"/>
                        <path d="M26.8333 7.38965L14.3888 12.8341V29.6021L26.8333 23.3488V7.38965ZM15.7674 21.3611L19.2777 19.5967C19.382 19.5442 19.4956 19.5127 19.6121 19.5041C19.7285 19.4954 19.8455 19.5099 19.9565 19.5466C20.0673 19.5831 20.17 19.6413 20.2584 19.7175C20.3468 19.7937 20.4194 19.8867 20.4719 19.9909C20.5244 20.0952 20.5558 20.2088 20.5645 20.3254C20.5731 20.4419 20.5587 20.5589 20.5221 20.6697C20.4856 20.7806 20.4274 20.8832 20.3512 20.9716C20.275 21.0601 20.1819 21.1326 20.0778 21.1851L16.5666 22.9496C16.4623 23.0021 16.3486 23.0336 16.2321 23.0423C16.1157 23.0508 15.9987 23.0365 15.8879 22.9998C15.7769 22.9633 15.6744 22.9051 15.5859 22.8289C15.4974 22.7526 15.4249 22.6597 15.3724 22.5553C15.3198 22.4511 15.2883 22.3374 15.2798 22.221C15.2711 22.1045 15.2856 21.9875 15.3221 21.8766C15.3588 21.7658 15.4168 21.6631 15.4931 21.5747C15.5694 21.4863 15.6632 21.4136 15.7674 21.3611ZM20.1275 24.715L16.5657 26.5051C16.4613 26.5576 16.3478 26.5892 16.2313 26.5977C16.1149 26.6064 15.9978 26.592 15.8869 26.5554C15.7761 26.5188 15.6734 26.4606 15.5851 26.3844C15.4966 26.3082 15.424 26.2153 15.3715 26.111C15.3189 26.0066 15.2875 25.8931 15.2788 25.7766C15.2703 25.6602 15.2846 25.543 15.3213 25.4322C15.3578 25.3213 15.416 25.2187 15.4922 25.1302C15.5685 25.0418 15.6614 24.9693 15.7658 24.9168L19.3275 23.1265C19.4318 23.074 19.5453 23.0425 19.6618 23.0338C19.7783 23.0253 19.8953 23.0396 20.0062 23.0763C20.1172 23.1128 20.2197 23.171 20.3082 23.2472C20.3967 23.3235 20.4692 23.4164 20.5217 23.5208C20.5742 23.625 20.6057 23.7387 20.6143 23.8551C20.623 23.9716 20.6085 24.0886 20.572 24.1995C20.5353 24.3103 20.4772 24.413 20.4009 24.5014C20.3247 24.5898 20.2318 24.6625 20.1275 24.715Z" fill="currentColor"/>
                    </svg>

                    <div class="name">
                        {{ lang.trans_461 }}
                    </div>

                </div>

                <div v-if="game.game.variant" class="wrap last">

                    <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.326538 10.3645L13.0741 25.084L8.82703 10.3645H0.326538Z" fill="currentColor"/>
                        <path d="M9.77344 10.3645L14.4937 26.7222L19.2143 10.3645H9.77344Z" fill="currentColor"/>
                        <path d="M4.22172 4.16992L0.277832 9.45461H8.17147L4.22172 4.16992Z" fill="currentColor"/>
                        <path d="M28.7223 9.45437L24.7784 4.16968L20.8347 9.45437H28.7223Z" fill="currentColor"/>
                        <path d="M9.07565 9.14511L13.3594 3.41138H4.79236L9.07565 9.14511Z" fill="currentColor"/>
                        <path d="M24.208 3.41138H15.635L19.9247 9.15107L24.208 3.41138Z" fill="currentColor"/>
                        <path d="M19.0143 9.45444L14.5001 3.41138L9.98596 9.45444H19.0143Z" fill="currentColor"/>
                        <path d="M15.9141 25.0907L28.6676 10.3652H20.1672L15.9141 25.0907Z" fill="currentColor"/>
                        <path d="M3.75742 3.82827L4.3146 2.57826L5.33821 2.24751L4.3146 1.91231L3.75742 0.666748L3.20025 1.91231L2.17664 2.24751L3.20025 2.57826L3.75742 3.82827Z" fill="currentColor"/>
                        <path d="M2.32134 5.73711L2.67326 4.95457L3.31635 4.74804L2.67326 4.53578L2.32134 3.74707L1.96328 4.53578L1.32019 4.74804L1.96328 4.95457L2.32134 5.73711Z" fill="currentColor"/>
                        <path d="M6.8995 3.04167L7.17862 2.42268L7.68205 2.2589L7.17862 2.09511L6.8995 1.47021L6.62038 2.09511L6.1106 2.2589L6.62038 2.42268L6.8995 3.04167Z" fill="currentColor"/>
                    </svg>

                    <div class="name">
                        {{ lang.trans_450 }}
                    </div>

                </div>

            </div>

            <div class="wrap">

                <div class="block">

                    <div class="top">

                        <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.6289 12.7866L22.9688 10.6655V4.30811C22.9688 3.4292 22.4238 2.64404 21.5977 2.3335L15.7383 0.13623C15.2637 -0.0454102 14.7363 -0.0454102 14.2559 0.13623L8.39648 2.3335C7.57031 2.64404 7.02539 3.4292 7.02539 4.30811V10.6655L1.36523 12.7866C0.544922 13.0972 0 13.8823 0 14.7612V21.2124C0 22.0093 0.451172 22.7417 1.16602 23.0991L7.02539 26.0288C7.61719 26.3276 8.32031 26.3276 8.91211 26.0288L15 22.9819L21.0879 26.0288C21.6797 26.3276 22.3828 26.3276 22.9746 26.0288L28.834 23.0991C29.5488 22.7417 30 22.0093 30 21.2124V14.7612C30 13.8823 29.4551 13.0972 28.6289 12.7866ZM20.9766 10.7124L15.9961 12.5815V8.58545L20.9766 6.41748V10.7124ZM9.02344 4.22607L15 1.98779L20.9766 4.22607V4.26123L15 6.68701L9.02344 4.26123V4.22607ZM13.9453 21.2827L8.96484 23.7729V19.1382L13.9453 16.8647V21.2827ZM13.9453 14.7202L7.96875 17.146L1.99219 14.7202V14.6851L7.96875 12.4468L13.9453 14.6851V14.7202ZM28.0078 21.2827L23.0273 23.7729V19.1382L28.0078 16.8647V21.2827ZM28.0078 14.7202L22.0312 17.146L16.0547 14.7202V14.6851L22.0312 12.4468L28.0078 14.6851V14.7202Z" fill="currentColor"/>
                        </svg>

                        <div class="name">
                            {{ lang.trans_96 }}
                        </div>

                    </div>

                    <div class="center">
                        <img :src="`${domain}/static/img/rules_1.svg`">

                        <ul>
                            <li>
                                {{ lang.trans_462 }}
                            </li>

                            <li>
                                {{ lang.trans_463 }}
                            </li>

                            <li>
                                {{ lang.trans_464 }}
                            </li>

                            <li>
                                {{ lang.trans_465 }}
                            </li>

                            <li>
                                {{ lang.trans_466 }}
                            </li>
                        </ul>

                    </div>

                    <div class="bottom">
                        {{ lang.trans_467 }}
                    </div>

                </div>

                <div class="block">

                    <div class="top">

                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.0624 19.4375H28.9926C28.6862 20.5981 28.2246 21.7085 27.6145 22.7532L29.0816 24.2202C29.2574 24.396 29.3562 24.6344 29.3562 24.883C29.3562 25.1318 29.2574 25.3702 29.0816 25.546L25.546 29.0814C25.18 29.4474 24.5862 29.4476 24.2202 29.0814L22.7531 27.6144C21.7085 28.2246 20.5981 28.6862 19.4376 28.9926V31.0626C19.4376 31.5802 19.0178 32 18.5 32H13.5C12.9823 32 12.5625 31.5802 12.5625 31.0626V28.9926C11.4019 28.6862 10.2916 28.2246 9.24694 27.6144L7.77987 29.0814C7.41381 29.4474 6.82019 29.4476 6.45407 29.0814L2.91857 25.546C2.74275 25.3702 2.64393 25.1316 2.64393 24.883C2.64393 24.6345 2.74275 24.396 2.91857 24.2202L4.38563 22.7532C3.77538 21.7085 3.31387 20.5981 3.00738 19.4375H0.937502C0.419744 19.4375 0 19.0177 0 18.5V13.5C0 12.9823 0.419744 12.5625 0.937502 12.5625H3.00738C3.31381 11.4019 3.77538 10.2916 4.38557 9.24694L2.91851 7.77994C2.74269 7.60412 2.64387 7.36569 2.64387 7.117C2.64387 6.86838 2.74263 6.62993 2.91851 6.45412L6.45401 2.91857C6.62981 2.74275 6.86826 2.64393 7.11688 2.64393C7.3655 2.64393 7.60393 2.74275 7.77975 2.91857L9.24682 4.38563C10.2914 3.77544 11.4018 3.31393 12.5624 3.00743V0.937503C12.5624 0.419749 12.9821 0 13.4999 0H18.4998C19.0176 0 19.4374 0.419749 19.4374 0.937503V3.00737C20.598 3.31381 21.7084 3.77538 22.7529 4.38556L24.22 2.91851C24.3958 2.74269 24.6342 2.64387 24.8829 2.64387C25.1315 2.64387 25.3699 2.74269 25.5457 2.91851L29.0813 6.45407C29.2571 6.62987 29.3558 6.86832 29.3558 7.11694C29.3558 7.36562 29.2571 7.604 29.0813 7.77988L27.6141 9.24688C28.2243 10.2915 28.6859 11.4018 28.9924 12.5624H31.0624C31.5802 12.5624 32 12.9822 32 13.4999V18.5C32 19.0177 31.5802 19.4375 31.0624 19.4375ZM16 7.25C11.1752 7.25 7.25 11.1753 7.25 15.9999C7.25 20.8248 11.1753 24.7501 16 24.7501C20.8247 24.7501 24.7499 20.8248 24.7499 15.9999C24.7499 11.1753 20.8249 7.25 16 7.25Z" fill="currentColor"/>
                            <path d="M19.75 20.6875H16.9375V18.8124C16.9375 18.2948 16.5177 17.875 16 17.875C15.4822 17.875 15.0626 18.2948 15.0626 18.8124V20.6875H12.25C11.7322 20.6875 11.3125 20.2678 11.3125 19.75V12.25C11.3125 11.7322 11.7322 11.3125 12.25 11.3125H19.75C20.2678 11.3125 20.6876 11.7322 20.6876 12.25V19.75C20.6876 20.2678 20.2678 20.6875 19.75 20.6875Z" fill="currentColor"/>
                        </svg>

                        <div class="name">
                            {{ lang.trans_97 }}
                        </div>

                    </div>

                    <div class="center">

                        <img :src="`${domain}/static/img/rules_2.svg`">

                        <div class="wrap">

                            <div class="name">
                                {{ lang.trans_468 }} - {{ format_number(game.game.capacity_ord) }}
                            </div>

                            <div class="wrap">

                                <div class="name bottom">
                                    {{ lang.trans_469 }}
                                </div>

                                <div class="name bottom left">
                                    {{ lang.trans_470 }}
                                </div>

                                <div class="name">
                                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.95396 5.02066L8.76064 4.5L5.46997 5.93799L6.6633 6.45999L9.95396 5.02066Z" fill="currentColor"/>
                                        <path d="M19.6699 4.27258L10.6667 0.333252L1.66663 4.27258L3.80929 5.2099L8.49328 3.16124C8.57739 3.12454 8.66817 3.10559 8.75994 3.10559C8.85171 3.10559 8.9425 3.12454 9.02661 3.16124L11.8866 4.40591C12.0053 4.45789 12.1062 4.54333 12.1771 4.65178C12.248 4.76024 12.2858 4.887 12.2858 5.01658C12.2858 5.14615 12.248 5.27291 12.1771 5.38137C12.1062 5.48982 12.0053 5.57526 11.8866 5.62724L8.32661 7.1879L10.6667 8.2119L19.6699 4.27258Z" fill="currentColor"/>
                                        <path d="M0.666626 5.29199V17.1972L9.99994 21.9452V9.37532L7.33328 8.20599V11.606C7.33333 11.7196 7.30433 11.8314 7.24903 11.9307C7.19373 12.03 7.11396 12.1134 7.0173 12.1733C6.92064 12.233 6.81031 12.2671 6.69678 12.2723C6.58324 12.2774 6.47027 12.2535 6.36861 12.2026L3.70195 10.8693C3.59113 10.8139 3.49793 10.7287 3.43282 10.6234C3.3677 10.518 3.33322 10.3965 3.33329 10.2727V6.45866L0.666626 5.29199Z" fill="currentColor"/>
                                        <path d="M4.66663 9.86065L5.99996 10.5273V7.62532L4.66663 7.04199V9.86065Z" fill="currentColor"/>
                                        <path d="M20.6666 5.29199L11.3333 9.37532V21.9513L20.6666 17.2613V5.29199ZM12.3672 15.7706L14.9999 14.4473C15.0782 14.4079 15.1634 14.3843 15.2507 14.3779C15.338 14.3713 15.4258 14.3822 15.509 14.4097C15.5921 14.4371 15.6691 14.4807 15.7355 14.5379C15.8017 14.595 15.8562 14.6648 15.8956 14.7429C15.935 14.8212 15.9585 14.9064 15.9651 14.9938C15.9715 15.0812 15.9607 15.1689 15.9332 15.252C15.9058 15.3352 15.8622 15.4121 15.805 15.4785C15.7479 15.5448 15.6781 15.5992 15.6 15.6386L12.9666 16.962C12.8884 17.0014 12.8031 17.0249 12.7158 17.0314C12.6284 17.0379 12.5407 17.0271 12.4576 16.9996C12.3743 16.9722 12.2974 16.9286 12.2311 16.8714C12.1647 16.8142 12.1104 16.7445 12.071 16.6663C12.0315 16.5881 12.0079 16.5028 12.0015 16.4155C11.995 16.3281 12.0059 16.2404 12.0332 16.1572C12.0607 16.0741 12.1043 15.9971 12.1615 15.9308C12.2187 15.8645 12.2891 15.81 12.3672 15.7706ZM15.6373 18.286L12.9659 19.6286C12.8877 19.668 12.8025 19.6917 12.7151 19.6981C12.6278 19.7046 12.54 19.6937 12.4568 19.6663C12.3737 19.6388 12.2967 19.5952 12.2305 19.5381C12.1641 19.4809 12.1096 19.4112 12.0702 19.333C12.0309 19.2547 12.0073 19.1696 12.0008 19.0822C11.9944 18.9949 12.0051 18.907 12.0326 18.8239C12.06 18.7407 12.1036 18.6638 12.1608 18.5974C12.2181 18.5311 12.2877 18.4767 12.366 18.4373L15.0372 17.0946C15.1155 17.0552 15.2007 17.0317 15.288 17.0251C15.3754 17.0187 15.4631 17.0295 15.5463 17.057C15.6295 17.0844 15.7064 17.128 15.7728 17.1852C15.8392 17.2424 15.8935 17.3121 15.9329 17.3903C15.9723 17.4685 15.996 17.5538 16.0024 17.6411C16.0089 17.7284 15.998 17.8162 15.9706 17.8994C15.9431 17.9825 15.8995 18.0595 15.8424 18.1258C15.7852 18.1921 15.7155 18.2466 15.6373 18.286Z" fill="currentColor"/>
                                    </svg>
                                </div>

                                <div class="name left">

                                    <div class="name">
                                        {{ format_number(game.game.variable_costs_ord) }}₽
                                    </div>

                                    <div class="desc">

                                        + {{ format_number(game.game.raw_for_standart) }}
    
                                        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.9941 8.85767L16.2207 7.4436V3.20532C16.2207 2.61938 15.8574 2.09595 15.3066 1.88892L11.4004 0.424072C11.084 0.302979 10.7324 0.302979 10.4121 0.424072L6.50586 1.88892C5.95508 2.09595 5.5918 2.61938 5.5918 3.20532V7.4436L1.81836 8.85767C1.27148 9.0647 0.908203 9.58813 0.908203 10.1741V14.4749C0.908203 15.0061 1.20898 15.4944 1.68555 15.7327L5.5918 17.6858C5.98633 17.885 6.45508 17.885 6.84961 17.6858L10.9082 15.6545L14.9668 17.6858C15.3613 17.885 15.8301 17.885 16.2246 17.6858L20.1309 15.7327C20.6074 15.4944 20.9082 15.0061 20.9082 14.4749V10.1741C20.9082 9.58813 20.5449 9.0647 19.9941 8.85767ZM14.8926 7.47485L11.5723 8.72095V6.05688L14.8926 4.61157V7.47485ZM6.92383 3.15063L10.9082 1.65845L14.8926 3.15063V3.17407L10.9082 4.79126L6.92383 3.17407V3.15063ZM10.2051 14.5217L6.88477 16.1819V13.092L10.2051 11.5764V14.5217ZM10.2051 10.1467L6.2207 11.7639L2.23633 10.1467V10.1233L6.2207 8.6311L10.2051 10.1233V10.1467ZM19.5801 14.5217L16.2598 16.1819V13.092L19.5801 11.5764V14.5217ZM19.5801 10.1467L15.5957 11.7639L11.6113 10.1467V10.1233L15.5957 8.6311L19.5801 10.1233V10.1467Z" fill="currentColor"/>
                                        </svg>

                                    </div>

                                </div>

                                <div v-if="game.game.variant" class="name">
                                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.036499 7.27344L9.59719 18.3131L6.41187 7.27344H0.036499Z" fill="currentColor"/>
                                        <path d="M7.1217 7.27344L10.6619 19.5417L14.2023 7.27344H7.1217Z" fill="currentColor"/>
                                        <path d="M2.95792 2.6272L0 6.59071H5.92023L2.95792 2.6272Z" fill="currentColor"/>
                                        <path d="M21.3333 6.59071L18.3754 2.6272L15.4176 6.59071H21.3333Z" fill="currentColor"/>
                                        <path d="M6.59833 6.35889L9.81112 2.05859H3.38586L6.59833 6.35889Z" fill="currentColor"/>
                                        <path d="M17.9477 2.05859H11.5179L14.7352 6.36336L17.9477 2.05859Z" fill="currentColor"/>
                                        <path d="M14.0524 6.59089L10.6668 2.05859L7.28113 6.59089H14.0524Z" fill="currentColor"/>
                                        <path d="M11.7272 18.3178L21.2923 7.27368H14.917L11.7272 18.3178Z" fill="currentColor"/>
                                        <path d="M2.60966 2.37114L3.02754 1.43364L3.79525 1.18557L3.02754 0.93417L2.60966 0L2.19178 0.93417L1.42407 1.18557L2.19178 1.43364L2.60966 2.37114Z" fill="currentColor"/>
                                        <path d="M1.5326 3.80283L1.79654 3.21593L2.27886 3.06103L1.79654 2.90184L1.5326 2.3103L1.26406 2.90184L0.781738 3.06103L1.26406 3.21593L1.5326 3.80283Z" fill="currentColor"/>
                                        <path d="M4.96631 1.78113L5.17565 1.31689L5.55323 1.19405L5.17565 1.07121L4.96631 0.602539L4.75697 1.07121L4.37463 1.19405L4.75697 1.31689L4.96631 1.78113Z" fill="currentColor"/>
                                    </svg>
                                </div>

                                <div v-if="game.game.variant" class="name left">

                                    <div class="name">
                                        {{ format_number(game.game.premium_variable_costs_ord) }}₽
                                    </div>

                                    <div class="desc">

                                        + {{ format_number(game.game.raw_for_premium) }}

                                        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.9941 8.85767L16.2207 7.4436V3.20532C16.2207 2.61938 15.8574 2.09595 15.3066 1.88892L11.4004 0.424072C11.084 0.302979 10.7324 0.302979 10.4121 0.424072L6.50586 1.88892C5.95508 2.09595 5.5918 2.61938 5.5918 3.20532V7.4436L1.81836 8.85767C1.27148 9.0647 0.908203 9.58813 0.908203 10.1741V14.4749C0.908203 15.0061 1.20898 15.4944 1.68555 15.7327L5.5918 17.6858C5.98633 17.885 6.45508 17.885 6.84961 17.6858L10.9082 15.6545L14.9668 17.6858C15.3613 17.885 15.8301 17.885 16.2246 17.6858L20.1309 15.7327C20.6074 15.4944 20.9082 15.0061 20.9082 14.4749V10.1741C20.9082 9.58813 20.5449 9.0647 19.9941 8.85767ZM14.8926 7.47485L11.5723 8.72095V6.05688L14.8926 4.61157V7.47485ZM6.92383 3.15063L10.9082 1.65845L14.8926 3.15063V3.17407L10.9082 4.79126L6.92383 3.17407V3.15063ZM10.2051 14.5217L6.88477 16.1819V13.092L10.2051 11.5764V14.5217ZM10.2051 10.1467L6.2207 11.7639L2.23633 10.1467V10.1233L6.2207 8.6311L10.2051 10.1233V10.1467ZM19.5801 14.5217L16.2598 16.1819V13.092L19.5801 11.5764V14.5217ZM19.5801 10.1467L15.5957 11.7639L11.6113 10.1467V10.1233L15.5957 8.6311L19.5801 10.1233V10.1467Z" fill="currentColor"/>
                                        </svg>

                                    </div>

                                </div>

                            </div>

                            <div class="name">
                                {{ lang.trans_471 }} - {{ format_number(game.game.capacity_auto) }}
                            </div>

                            <div class="wrap">

                                <div class="name bottom">
                                    {{ lang.trans_469 }}
                                </div>

                                <div class="name bottom left">
                                    {{ lang.trans_470 }}
                                </div>

                                <div class="name">
                                    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.95396 5.02066L8.76064 4.5L5.46997 5.93799L6.6633 6.45999L9.95396 5.02066Z" fill="currentColor"/>
                                        <path d="M19.6699 4.27258L10.6667 0.333252L1.66663 4.27258L3.80929 5.2099L8.49328 3.16124C8.57739 3.12454 8.66817 3.10559 8.75994 3.10559C8.85171 3.10559 8.9425 3.12454 9.02661 3.16124L11.8866 4.40591C12.0053 4.45789 12.1062 4.54333 12.1771 4.65178C12.248 4.76024 12.2858 4.887 12.2858 5.01658C12.2858 5.14615 12.248 5.27291 12.1771 5.38137C12.1062 5.48982 12.0053 5.57526 11.8866 5.62724L8.32661 7.1879L10.6667 8.2119L19.6699 4.27258Z" fill="currentColor"/>
                                        <path d="M0.666626 5.29199V17.1972L9.99994 21.9452V9.37532L7.33328 8.20599V11.606C7.33333 11.7196 7.30433 11.8314 7.24903 11.9307C7.19373 12.03 7.11396 12.1134 7.0173 12.1733C6.92064 12.233 6.81031 12.2671 6.69678 12.2723C6.58324 12.2774 6.47027 12.2535 6.36861 12.2026L3.70195 10.8693C3.59113 10.8139 3.49793 10.7287 3.43282 10.6234C3.3677 10.518 3.33322 10.3965 3.33329 10.2727V6.45866L0.666626 5.29199Z" fill="currentColor"/>
                                        <path d="M4.66663 9.86065L5.99996 10.5273V7.62532L4.66663 7.04199V9.86065Z" fill="currentColor"/>
                                        <path d="M20.6666 5.29199L11.3333 9.37532V21.9513L20.6666 17.2613V5.29199ZM12.3672 15.7706L14.9999 14.4473C15.0782 14.4079 15.1634 14.3843 15.2507 14.3779C15.338 14.3713 15.4258 14.3822 15.509 14.4097C15.5921 14.4371 15.6691 14.4807 15.7355 14.5379C15.8017 14.595 15.8562 14.6648 15.8956 14.7429C15.935 14.8212 15.9585 14.9064 15.9651 14.9938C15.9715 15.0812 15.9607 15.1689 15.9332 15.252C15.9058 15.3352 15.8622 15.4121 15.805 15.4785C15.7479 15.5448 15.6781 15.5992 15.6 15.6386L12.9666 16.962C12.8884 17.0014 12.8031 17.0249 12.7158 17.0314C12.6284 17.0379 12.5407 17.0271 12.4576 16.9996C12.3743 16.9722 12.2974 16.9286 12.2311 16.8714C12.1647 16.8142 12.1104 16.7445 12.071 16.6663C12.0315 16.5881 12.0079 16.5028 12.0015 16.4155C11.995 16.3281 12.0059 16.2404 12.0332 16.1572C12.0607 16.0741 12.1043 15.9971 12.1615 15.9308C12.2187 15.8645 12.2891 15.81 12.3672 15.7706ZM15.6373 18.286L12.9659 19.6286C12.8877 19.668 12.8025 19.6917 12.7151 19.6981C12.6278 19.7046 12.54 19.6937 12.4568 19.6663C12.3737 19.6388 12.2967 19.5952 12.2305 19.5381C12.1641 19.4809 12.1096 19.4112 12.0702 19.333C12.0309 19.2547 12.0073 19.1696 12.0008 19.0822C11.9944 18.9949 12.0051 18.907 12.0326 18.8239C12.06 18.7407 12.1036 18.6638 12.1608 18.5974C12.2181 18.5311 12.2877 18.4767 12.366 18.4373L15.0372 17.0946C15.1155 17.0552 15.2007 17.0317 15.288 17.0251C15.3754 17.0187 15.4631 17.0295 15.5463 17.057C15.6295 17.0844 15.7064 17.128 15.7728 17.1852C15.8392 17.2424 15.8935 17.3121 15.9329 17.3903C15.9723 17.4685 15.996 17.5538 16.0024 17.6411C16.0089 17.7284 15.998 17.8162 15.9706 17.8994C15.9431 17.9825 15.8995 18.0595 15.8424 18.1258C15.7852 18.1921 15.7155 18.2466 15.6373 18.286Z" fill="currentColor"/>
                                    </svg>
                                </div>

                                <div class="name left">

                                    <div class="name">
                                        {{ format_number(game.game.variable_costs_auto) }}₽
                                    </div>

                                    <div class="desc">
                                        + {{ format_number(game.game.raw_for_standart) }}

                                        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.9941 8.85767L16.2207 7.4436V3.20532C16.2207 2.61938 15.8574 2.09595 15.3066 1.88892L11.4004 0.424072C11.084 0.302979 10.7324 0.302979 10.4121 0.424072L6.50586 1.88892C5.95508 2.09595 5.5918 2.61938 5.5918 3.20532V7.4436L1.81836 8.85767C1.27148 9.0647 0.908203 9.58813 0.908203 10.1741V14.4749C0.908203 15.0061 1.20898 15.4944 1.68555 15.7327L5.5918 17.6858C5.98633 17.885 6.45508 17.885 6.84961 17.6858L10.9082 15.6545L14.9668 17.6858C15.3613 17.885 15.8301 17.885 16.2246 17.6858L20.1309 15.7327C20.6074 15.4944 20.9082 15.0061 20.9082 14.4749V10.1741C20.9082 9.58813 20.5449 9.0647 19.9941 8.85767ZM14.8926 7.47485L11.5723 8.72095V6.05688L14.8926 4.61157V7.47485ZM6.92383 3.15063L10.9082 1.65845L14.8926 3.15063V3.17407L10.9082 4.79126L6.92383 3.17407V3.15063ZM10.2051 14.5217L6.88477 16.1819V13.092L10.2051 11.5764V14.5217ZM10.2051 10.1467L6.2207 11.7639L2.23633 10.1467V10.1233L6.2207 8.6311L10.2051 10.1233V10.1467ZM19.5801 14.5217L16.2598 16.1819V13.092L19.5801 11.5764V14.5217ZM19.5801 10.1467L15.5957 11.7639L11.6113 10.1467V10.1233L15.5957 8.6311L19.5801 10.1233V10.1467Z" fill="currentColor"/>
                                        </svg>

                                    </div>

                                </div>

                                <div v-if="game.game.variant" class="name">
                                    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.036499 7.27344L9.59719 18.3131L6.41187 7.27344H0.036499Z" fill="currentColor"/>
                                        <path d="M7.1217 7.27344L10.6619 19.5417L14.2023 7.27344H7.1217Z" fill="currentColor"/>
                                        <path d="M2.95792 2.6272L0 6.59071H5.92023L2.95792 2.6272Z" fill="currentColor"/>
                                        <path d="M21.3333 6.59071L18.3754 2.6272L15.4176 6.59071H21.3333Z" fill="currentColor"/>
                                        <path d="M6.59833 6.35889L9.81112 2.05859H3.38586L6.59833 6.35889Z" fill="currentColor"/>
                                        <path d="M17.9477 2.05859H11.5179L14.7352 6.36336L17.9477 2.05859Z" fill="currentColor"/>
                                        <path d="M14.0524 6.59089L10.6668 2.05859L7.28113 6.59089H14.0524Z" fill="currentColor"/>
                                        <path d="M11.7272 18.3178L21.2923 7.27368H14.917L11.7272 18.3178Z" fill="currentColor"/>
                                        <path d="M2.60966 2.37114L3.02754 1.43364L3.79525 1.18557L3.02754 0.93417L2.60966 0L2.19178 0.93417L1.42407 1.18557L2.19178 1.43364L2.60966 2.37114Z" fill="currentColor"/>
                                        <path d="M1.5326 3.80283L1.79654 3.21593L2.27886 3.06103L1.79654 2.90184L1.5326 2.3103L1.26406 2.90184L0.781738 3.06103L1.26406 3.21593L1.5326 3.80283Z" fill="currentColor"/>
                                        <path d="M4.96631 1.78113L5.17565 1.31689L5.55323 1.19405L5.17565 1.07121L4.96631 0.602539L4.75697 1.07121L4.37463 1.19405L4.75697 1.31689L4.96631 1.78113Z" fill="currentColor"/>
                                    </svg>
                                </div>

                                <div v-if="game.game.variant" class="name left">

                                    <div class="name">
                                        {{ format_number(game.game.premium_variable_costs_auto) }}₽
                                    </div>

                                   <div class="desc">

                                        + {{ format_number(game.game.raw_for_premium) }}

                                        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.9941 8.85767L16.2207 7.4436V3.20532C16.2207 2.61938 15.8574 2.09595 15.3066 1.88892L11.4004 0.424072C11.084 0.302979 10.7324 0.302979 10.4121 0.424072L6.50586 1.88892C5.95508 2.09595 5.5918 2.61938 5.5918 3.20532V7.4436L1.81836 8.85767C1.27148 9.0647 0.908203 9.58813 0.908203 10.1741V14.4749C0.908203 15.0061 1.20898 15.4944 1.68555 15.7327L5.5918 17.6858C5.98633 17.885 6.45508 17.885 6.84961 17.6858L10.9082 15.6545L14.9668 17.6858C15.3613 17.885 15.8301 17.885 16.2246 17.6858L20.1309 15.7327C20.6074 15.4944 20.9082 15.0061 20.9082 14.4749V10.1741C20.9082 9.58813 20.5449 9.0647 19.9941 8.85767ZM14.8926 7.47485L11.5723 8.72095V6.05688L14.8926 4.61157V7.47485ZM6.92383 3.15063L10.9082 1.65845L14.8926 3.15063V3.17407L10.9082 4.79126L6.92383 3.17407V3.15063ZM10.2051 14.5217L6.88477 16.1819V13.092L10.2051 11.5764V14.5217ZM10.2051 10.1467L6.2207 11.7639L2.23633 10.1467V10.1233L6.2207 8.6311L10.2051 10.1233V10.1467ZM19.5801 14.5217L16.2598 16.1819V13.092L19.5801 11.5764V14.5217ZM19.5801 10.1467L15.5957 11.7639L11.6113 10.1467V10.1233L15.5957 8.6311L19.5801 10.1233V10.1467Z" fill="currentColor"/>
                                        </svg>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                    <div class="bottom">
                        {{ lang.trans_472 }}
                    </div>

                </div>

                <div class="block">

                    <div class="top">

                        <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.9201 6.02269H0.0800781C0.125741 5.83978 0.205844 5.65687 0.297168 5.47422L2.62853 1.24565C3.05149 0.468637 3.86269 0 4.74282 0H27.2571C28.1372 0 28.9484 0.468624 29.3714 1.24565L31.7028 5.47422C31.7943 5.65687 31.8742 5.83978 31.9198 6.02269H31.9201Z" fill="currentColor"/>
                            <path d="M29.125 15.1572C28.7536 15.2304 28.372 15.2681 27.9937 15.2681C26.4383 15.2681 25.0439 14.6271 24.0199 13.6087C22.9868 14.6327 21.5673 15.2681 20.0005 15.2681C18.4428 15.2681 17.0462 14.6258 16.0212 13.6077C14.988 14.6326 13.5673 15.2681 12.0005 15.2681C10.4428 15.2681 9.04744 14.6271 8.0222 13.6087C6.9903 14.6327 5.57191 15.2681 4.00738 15.2681C3.62906 15.2681 3.24741 15.2304 2.87013 15.1572C2.73187 15.1309 2.60381 15.0771 2.46912 15.0406V27.8967C2.46912 29.234 3.54334 30.3082 4.88061 30.3082H7.21661V19.2212C7.21661 18.684 7.65207 18.2487 8.18908 18.2487H14.0679C14.6052 18.2487 15.0404 18.6842 15.0404 19.2212V30.3082H27.1207C28.458 30.3082 29.5322 29.234 29.5322 27.8967V15.0393C29.3963 15.077 29.2659 15.1296 29.1253 15.1569L29.125 15.1572ZM24.7845 22.6406C24.7845 23.1778 24.349 23.6131 23.812 23.6131H20.3913C19.8541 23.6131 19.4188 23.1776 19.4188 22.6406V19.2199C19.4188 18.6827 19.8543 18.2475 20.3913 18.2475H23.812C24.3492 18.2475 24.7845 18.6829 24.7845 19.2199V22.6406Z" fill="currentColor"/>
                            <path d="M31.9201 7.73703H0.0801045C0.0525538 7.73703 0.0275533 7.72887 0 7.72785V9.40563C0 11.337 1.30279 13.1084 3.2 13.4743C5.75993 13.9771 8 12.0227 8 9.55413C8 11.7597 9.79442 13.5541 12 13.5541C14.2056 13.5541 16 11.7597 16 9.55413C16 11.7597 17.7944 13.5541 20 13.5541C22.2056 13.5541 24 11.7597 24 9.55413C24 12.0228 26.2401 13.9771 28.8 13.474C30.6972 13.1082 32 11.3367 32 9.40537V7.72778C31.9724 7.7288 31.9474 7.73697 31.9199 7.73697L31.9201 7.73703Z" fill="currentColor"/>
                        </svg>

                        <div class="name">
                            {{ lang.trans_98 }}
                        </div>

                    </div>

                    <div class="center">
                        <img :src="`${domain}/static/img/rules_3.svg`">

                        <ul>
                            <li>
                                {{ lang.trans_473 }}
                            </li>

                            <li>
                                {{ lang.trans_474 }}
                            </li>

                            <li>
                                {{ lang.trans_475 }}
                            </li>

                            <li>
                                {{ lang.trans_476 }}
                            </li>

                            <li>
                                {{ lang.trans_477 }}
                            </li>
                        </ul>

                    </div>

                    <div class="bottom">
                        {{ lang.trans_478 }}
                    </div>

                </div>

                <div class="block">

                    <div class="top">

                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.37519 7.93751H15.6248C15.9227 7.93751 16.2011 7.79471 16.3797 7.55626C17.7453 5.73319 18.4376 3.71664 18.4376 1.56251V0.937501C18.4376 0.419751 18.0178 0 17.5 0C15.7638 0 13.4991 1.1245 12.5 1.67163C11.5009 1.1245 9.23625 0 7.49999 0C6.98224 0 6.5625 0.419751 6.5625 0.937501V1.56251C6.5625 3.71664 7.25481 5.73319 8.62032 7.55626C8.79888 7.79471 9.07737 7.93751 9.37519 7.93751Z" fill="currentColor"/>
                            <path d="M13.2258 21.7168V24.3711C13.7047 24.1126 13.9892 23.6354 14.0653 23.1828C14.1197 22.8597 14.1419 22.084 13.2258 21.7168Z" fill="currentColor"/>
                            <path d="M11.192 18.333C11.1263 18.6817 11.2108 19.0067 11.4074 19.1609C11.4842 19.2212 11.5707 19.2801 11.6632 19.338V17.5735C11.3903 17.7644 11.2417 18.0699 11.192 18.333Z" fill="currentColor"/>
                            <path d="M17.625 23.6459V18.9946C17.625 17.6953 18.5595 16.6013 19.847 16.3932C20.513 16.2855 21.0922 15.8702 21.4108 15.2777C20.0678 13.4202 18.4866 11.6904 16.9191 10.0934C16.7428 9.91369 16.5016 9.8125 16.25 9.8125H8.75001C8.49833 9.8125 8.25726 9.91369 8.08095 10.0933C4.08494 14.1646 0 19.0971 0 24.8125L6.21225e-05 24.8798C0.00881144 28.8059 3.25576 32.0001 7.23819 32.0001H17.7619C18.8753 32.0001 19.9311 31.7502 20.8749 31.3046C18.843 29.3422 17.625 26.602 17.625 23.6459ZM15.6061 23.4421C15.4869 24.1514 15.1302 24.8094 14.6014 25.2952C14.2061 25.6584 13.7392 25.907 13.2258 26.0328V26.625C13.2258 27.0565 12.876 27.4062 12.4445 27.4062C12.013 27.4062 11.6633 27.0565 11.6633 26.625V26.1058C11.0088 26.0517 10.4702 25.879 9.70414 25.3779C9.34308 25.1417 9.24183 24.6576 9.47802 24.2965C9.7142 23.9353 10.1983 23.8341 10.5595 24.0703C11.0025 24.3601 11.2852 24.4838 11.6633 24.5339V21.102C11.145 20.8652 10.7591 20.638 10.4431 20.3901C9.78282 19.8722 9.48139 18.973 9.65658 18.0434C9.84764 17.0298 10.553 16.2232 11.4975 15.9386C11.5533 15.9219 11.6086 15.9065 11.6633 15.8923V15.375C11.6633 14.9435 12.013 14.5938 12.4445 14.5938C12.876 14.5938 13.2258 14.9435 13.2258 15.375V15.8057C14.0095 15.9367 14.5629 16.321 14.8299 16.6273C15.1133 16.9526 15.0795 17.4461 14.7541 17.7296C14.4304 18.0117 13.9401 17.9794 13.656 17.6584C13.6373 17.6395 13.4971 17.5034 13.2258 17.4099V20.0538C13.3734 20.1087 13.521 20.1619 13.6658 20.2131C15.0897 20.7164 15.8513 21.9838 15.6061 23.4421Z" fill="currentColor"/>
                            <path d="M31.3538 18.2442C30.0809 18.0384 28.9663 17.2326 28.3795 16.0551C28.2998 15.895 28.1339 15.7961 27.955 15.7961H23.5451C23.3662 15.7961 23.2003 15.895 23.1205 16.0551C22.5338 17.2326 21.4192 18.0384 20.1463 18.2442C19.7755 18.3042 19.5 18.6189 19.5 18.9945V23.646C19.5 27.4146 21.9056 30.7491 25.4531 31.9512C25.6454 32.0165 25.8548 32.0165 26.0471 31.9512C29.5946 30.7491 32 27.4146 32 23.646V18.9945C32 18.6189 31.7246 18.3042 31.3538 18.2442ZM29.0646 22.8162L25.5291 26.3518C25.3461 26.5348 25.1062 26.6264 24.8662 26.6264C24.6263 26.6264 24.3864 26.5348 24.2033 26.3518L22.4357 24.584C22.0694 24.2179 22.0694 23.6243 22.4357 23.2582C22.8016 22.8921 23.3954 22.8921 23.7615 23.2582L24.8662 24.363L27.739 21.4904C28.1051 21.1243 28.6987 21.1243 29.0648 21.4904C29.4307 21.8566 29.4307 22.4502 29.0646 22.8162Z" fill="currentColor"/>
                        </svg>

                        <div class="name">
                            {{ lang.trans_209 }}
                        </div>

                    </div>

                    <div class="center">

                        <img :src="`${domain}/static/img/rules_4.svg`">

                        <div class="wrap">

                            <div class="name">
                                {{ lang.trans_479 }}
                            </div>

                            <div class="wrap_v2">

                                <div class="name">
                                    {{ lang.trans_480 }}
                                </div>

                                <div class="wrap">

                                    <div class="name">
                                        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.5859 8.85767L15.8125 7.4436V3.20532C15.8125 2.61938 15.4492 2.09595 14.8984 1.88892L10.9922 0.424072C10.6758 0.302979 10.3242 0.302979 10.0039 0.424072L6.09766 1.88892C5.54688 2.09595 5.18359 2.61938 5.18359 3.20532V7.4436L1.41016 8.85767C0.863281 9.0647 0.5 9.58813 0.5 10.1741V14.4749C0.5 15.0061 0.800781 15.4944 1.27734 15.7327L5.18359 17.6858C5.57812 17.885 6.04688 17.885 6.44141 17.6858L10.5 15.6545L14.5586 17.6858C14.9531 17.885 15.4219 17.885 15.8164 17.6858L19.7227 15.7327C20.1992 15.4944 20.5 15.0061 20.5 14.4749V10.1741C20.5 9.58813 20.1367 9.0647 19.5859 8.85767ZM14.4844 7.47485L11.1641 8.72095V6.05688L14.4844 4.61157V7.47485ZM6.51562 3.15063L10.5 1.65845L14.4844 3.15063V3.17407L10.5 4.79126L6.51562 3.17407V3.15063ZM9.79688 14.5217L6.47656 16.1819V13.092L9.79688 11.5764V14.5217ZM9.79688 10.1467L5.8125 11.7639L1.82812 10.1467V10.1233L5.8125 8.6311L9.79688 10.1233V10.1467ZM19.1719 14.5217L15.8516 16.1819V13.092L19.1719 11.5764V14.5217ZM19.1719 10.1467L15.1875 11.7639L11.2031 10.1467V10.1233L15.1875 8.6311L19.1719 10.1233V10.1467Z" fill="currentColor"/>
                                        </svg>
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.warehouse_costs_raw) }}₽
                                    </div>

                                    <div class="name">

                                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.28733 5.02066L8.09401 4.5L4.80334 5.93799L5.99668 6.45999L9.28733 5.02066Z" fill="currentColor"/>
                                            <path d="M19.0033 4.27258L10 0.333252L1 4.27258L3.14266 5.2099L7.82665 3.16124C7.91076 3.12454 8.00155 3.10559 8.09332 3.10559C8.18508 3.10559 8.27587 3.12454 8.35999 3.16124L11.2199 4.40591C11.3387 4.45789 11.4396 4.54333 11.5105 4.65178C11.5814 4.76024 11.6191 4.887 11.6191 5.01658C11.6191 5.14615 11.5814 5.27291 11.5105 5.38137C11.4396 5.48982 11.3387 5.57526 11.2199 5.62724L7.65999 7.1879L10 8.2119L19.0033 4.27258Z" fill="currentColor"/>
                                            <path d="M0 5.29199V17.1972L9.33331 21.9452V9.37532L6.66665 8.20599V11.606C6.6667 11.7196 6.6377 11.8314 6.5824 11.9307C6.5271 12.03 6.44733 12.1134 6.35068 12.1733C6.25402 12.233 6.14369 12.2671 6.03015 12.2723C5.91661 12.2774 5.80364 12.2535 5.70198 12.2026L3.03532 10.8693C2.92451 10.8139 2.8313 10.7287 2.76619 10.6234C2.70107 10.518 2.6666 10.3965 2.66666 10.2727V6.45866L0 5.29199Z" fill="currentColor"/>
                                            <path d="M4 9.86065L5.33333 10.5273V7.62532L4 7.04199V9.86065Z" fill="currentColor"/>
                                            <path d="M20 5.29199L10.6666 9.37532V21.9513L20 17.2613V5.29199ZM11.7006 15.7706L14.3333 14.4473C14.4116 14.4079 14.4967 14.3843 14.5841 14.3779C14.6714 14.3713 14.7592 14.3822 14.8424 14.4097C14.9255 14.4371 15.0025 14.4807 15.0689 14.5379C15.1351 14.595 15.1896 14.6648 15.229 14.7429C15.2684 14.8212 15.2919 14.9064 15.2984 14.9938C15.3048 15.0812 15.2941 15.1689 15.2666 15.252C15.2392 15.3352 15.1956 15.4121 15.1384 15.4785C15.0813 15.5448 15.0115 15.5992 14.9333 15.6386L12.3 16.962C12.2218 17.0014 12.1365 17.0249 12.0491 17.0314C11.9618 17.0379 11.874 17.0271 11.7909 16.9996C11.7077 16.9722 11.6308 16.9286 11.5645 16.8714C11.4981 16.8142 11.4437 16.7445 11.4043 16.6663C11.3649 16.5881 11.3413 16.5028 11.3349 16.4155C11.3284 16.3281 11.3392 16.2404 11.3666 16.1572C11.3941 16.0741 11.4376 15.9971 11.4949 15.9308C11.5521 15.8645 11.6224 15.81 11.7006 15.7706ZM14.9707 18.286L12.2993 19.6286C12.221 19.668 12.1359 19.6917 12.0485 19.6981C11.9612 19.7046 11.8734 19.6937 11.7902 19.6663C11.7071 19.6388 11.6301 19.5952 11.5638 19.5381C11.4975 19.4809 11.443 19.4112 11.4036 19.333C11.3642 19.2547 11.3407 19.1696 11.3342 19.0822C11.3278 18.9949 11.3385 18.907 11.366 18.8239C11.3934 18.7407 11.437 18.6638 11.4942 18.5974C11.5514 18.5311 11.6211 18.4767 11.6994 18.4373L14.3706 17.0946C14.4489 17.0552 14.534 17.0317 14.6214 17.0251C14.7087 17.0187 14.7965 17.0295 14.8797 17.057C14.9629 17.0844 15.0398 17.128 15.1062 17.1852C15.1725 17.2424 15.2269 17.3121 15.2663 17.3903C15.3057 17.4685 15.3293 17.5538 15.3357 17.6411C15.3423 17.7284 15.3314 17.8162 15.304 17.8994C15.2765 17.9825 15.2329 18.0595 15.1757 18.1258C15.1186 18.1921 15.0489 18.2466 14.9707 18.286Z" fill="currentColor"/>
                                        </svg>

                                        <svg v-if="game.game.variant" class="left" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.369873 7.27344L9.93057 18.3131L6.74524 7.27344H0.369873Z" fill="currentColor"/>
                                            <path d="M7.45508 7.27344L10.9953 19.5417L14.5357 7.27344H7.45508Z" fill="currentColor"/>
                                            <path d="M3.29129 2.6272L0.333374 6.59071H6.2536L3.29129 2.6272Z" fill="currentColor"/>
                                            <path d="M21.6667 6.59071L18.7087 2.6272L15.751 6.59071H21.6667Z" fill="currentColor"/>
                                            <path d="M6.9317 6.35889L10.1445 2.05859H3.71924L6.9317 6.35889Z" fill="currentColor"/>
                                            <path d="M18.281 2.05859H11.8513L15.0686 6.36336L18.281 2.05859Z" fill="currentColor"/>
                                            <path d="M14.3858 6.59089L11.0001 2.05859L7.6145 6.59089H14.3858Z" fill="currentColor"/>
                                            <path d="M12.0605 18.3178L21.6257 7.27368H15.2504L12.0605 18.3178Z" fill="currentColor"/>
                                            <path d="M2.94304 2.37114L3.36092 1.43364L4.12863 1.18557L3.36092 0.93417L2.94304 0L2.52515 0.93417L1.75745 1.18557L2.52515 1.43364L2.94304 2.37114Z" fill="currentColor"/>
                                            <path d="M1.86597 3.80283L2.12991 3.21593L2.61223 3.06103L2.12991 2.90184L1.86597 2.3103L1.59743 2.90184L1.11511 3.06103L1.59743 3.21593L1.86597 3.80283Z" fill="currentColor"/>
                                            <path d="M5.29969 1.78113L5.50902 1.31689L5.8866 1.19405L5.50902 1.07121L5.29969 0.602539L5.09035 1.07121L4.70801 1.19405L5.09035 1.31689L5.29969 1.78113Z" fill="currentColor"/>
                                        </svg>

                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.warehouse_costs_prod) }}₽
                                    </div>

                                </div>

                            </div>

                            <div class="wrap_v2">

                                <div class="name">
                                    {{ lang.trans_481 }}
                                </div>

                                <div class="wrap">

                                    <div class="name">
                                        {{ lang.trans_482 }}
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.fixed_costs_ord) }}₽
                                    </div>

                                    <div class="name">
                                        {{ lang.trans_28 }}
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.fixed_costs_auto) }}₽
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="bottom">
                        {{ lang.trans_483 }}
                    </div>

                </div>

                <div class="block">

                    <div class="top">

                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.01857 13.0906H1.45498C0.651577 13.0906 0 13.7422 0 14.5456V30.5455C0 31.3483 0.651577 31.9999 1.45498 31.9999H5.01857C5.82197 31.9999 6.47287 31.3483 6.47287 30.5455V14.5456C6.47287 13.7422 5.82197 13.0906 5.01857 13.0906Z" fill="currentColor"/>
                            <path d="M13.5278 17.4536H9.96425C9.16016 17.4536 8.50928 18.1053 8.50928 18.9086V30.5444C8.50928 31.3486 9.16016 31.9994 9.96425 31.9994H13.5278C14.3312 31.9994 14.9821 31.3478 14.9821 30.5444V18.9086C14.9821 18.1053 14.3312 17.4536 13.5278 17.4536Z" fill="currentColor"/>
                            <path d="M22.0358 17.4536H18.4722C17.6688 17.4536 17.0178 18.1053 17.0178 18.9086V30.5444C17.0178 31.3486 17.6688 31.9994 18.4722 31.9994H22.0358C22.8399 31.9994 23.4908 31.3478 23.4908 30.5444V18.9086C23.4908 18.1053 22.8399 17.4536 22.0358 17.4536Z" fill="currentColor"/>
                            <path d="M30.545 13.0906H26.9815C26.178 13.0906 25.5271 13.7422 25.5271 14.5456V30.5455C25.5271 31.3491 26.178 31.9999 26.9815 31.9999H30.545C31.3484 31.9999 32.0001 31.3477 32.0001 30.5455V14.5456C32.0001 13.7422 31.3484 13.0906 30.545 13.0906Z" fill="currentColor"/>
                            <path d="M16.4242 8.76221V11.3808C17.2167 11.3283 18.0521 10.9566 18.0521 10.0844C18.0521 9.18433 17.1364 8.92085 16.4242 8.76221Z" fill="currentColor"/>
                            <path d="M14.1467 5.62863C14.1467 6.29041 14.639 6.67304 15.6309 6.87186V4.50317C14.7295 4.52973 14.1467 5.05943 14.1467 5.62863Z" fill="currentColor"/>
                            <path d="M16 0C11.5888 0 8 3.58946 8 8.00001C8 12.4092 11.5888 15.9987 16 15.9987C20.4113 15.9987 24 12.4092 24 8.00001C24 3.58946 20.4113 0 16 0ZM16.4241 12.7313V13.5503C16.4241 13.7757 16.2513 14.0004 16.0251 14.0004C15.8018 14.0004 15.631 13.7757 15.631 13.5503V12.7313C13.3958 12.6768 12.2839 11.341 12.2839 10.2958C12.2839 9.76819 12.6032 9.46316 13.103 9.46316C14.5845 9.46316 13.4325 11.2885 15.631 11.3804V8.61618C13.6701 8.26009 12.4827 7.40019 12.4827 5.93227C12.4827 4.13413 13.9779 3.20681 15.631 3.15506V2.4497C15.631 2.22434 15.8018 1.99967 16.0251 1.99967C16.2513 1.99967 16.4241 2.22434 16.4241 2.4497V3.15506C17.455 3.1823 19.5718 3.82912 19.5718 5.12613C19.5718 5.64154 19.1865 5.9452 18.7364 5.9452C17.8757 5.9452 17.888 4.53108 16.4241 4.50384V7.01686C18.1698 7.38792 19.716 7.90333 19.716 9.94045C19.716 11.712 18.3939 12.6107 16.4241 12.7313Z" fill="currentColor"/>
                        </svg>

                        <div class="name">
                            {{ lang.trans_258 }}
                        </div>

                    </div>

                    <div class="center">

                        <img :src="`${domain}/static/img/rules_5.svg`">

                        <div class="wrap">

                            <div class="name">
                                {{ lang.trans_484 }}
                            </div>

                            <div class="wrap_v2">

                                <div class="name">
                                    {{ lang.trans_485 }}
                                </div>

                                <div class="wrap">

                                    <div class="name">
                                        {{ lang.trans_486 }}
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.constr_cost_ord) }}₽
                                    </div>

                                    <div class="name">
                                        {{ lang.trans_487 }}
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.constr_cost_auto) }}₽
                                    </div>

                                </div>

                            </div>

                            <div class="wrap_v2">

                                <div class="name">
                                    {{ lang.trans_488 }}
                                </div>

                                <div class="wrap">

                                    <div class="name">
                                        {{ lang.trans_482 }}
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.money_liq_ord) }}₽
                                    </div>

                                    <div class="name">
                                        {{ lang.trans_28 }}
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.money_liq_auto) }}₽
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="bottom">
                        {{ lang.trans_489 }}
                    </div>

                </div>

                <div class="block">

                    <div class="top">

                        <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.2448 6.56215C13.104 5.87895 10.6654 6.93119 10.6258 6.94829L9.81736 5.28245C9.96386 5.21304 11.6576 4.44412 14.5681 4.44412C16.627 4.44412 19.3018 4.84467 22.4616 6.14141C23.8858 6.72578 25.6396 6.25734 26.3395 4.90338C26.3442 4.89427 26.7729 4.05015 26.7729 4.05015L22.5808 1.98584C19.5696 0.503881 16.9722 0.000922793 14.7182 0C11.9332 0 9.67093 0.768001 7.7923 1.40535C6.12692 1.97144 5.06931 2.41587 4.19312 1.9859L3.3545 1.57304L0 8.17789C0.523999 8.40676 2.45718 9.68479 5.86693 10.4829C10.1029 11.4799 14.5022 9.93839 17.2448 6.56215Z" fill="currentColor"/>
                            <path d="M28.0236 5.71686C26.8595 7.96639 24.07 8.80337 21.7404 7.84517C20.9136 7.50621 20.0988 7.22227 19.2959 6.99328C18.8612 7.4947 18.4964 8.10282 17.2411 9.23063H32V5.53833H28.1142L28.0236 5.71686Z" fill="currentColor"/>
                            <path d="M8.43956 12.6281C8.14694 12.6281 7.85488 12.5967 7.5625 12.577V22.1536H32V11.0767H14.6038C12.7252 12.0744 10.6236 12.6281 8.43956 12.6281ZM22.5625 17.5382H28.1874V19.3844H22.5625V17.5382Z" fill="currentColor"/>
                        </svg>

                        <div class="name">
                            {{ lang.trans_22 }}
                        </div>

                    </div>

                    <div class="center">

                        <img :src="`${domain}/static/img/rules_6.svg`">

                        <div class="wrap">

                            <div class="name">
                                {{ lang.trans_490 }}
                            </div>

                            <div class="wrap_v2">

                                <div class="name">
                                    {{ lang.trans_491 }}
                                </div>

                                <div class="wrap">

                                    <div class="name">
                                        {{ lang.trans_492 }}
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.loan_interest) }}%
                                    </div>

                                    <div class="name">
                                        {{ lang.trans_493 }}
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.loan_limit) }}₽
                                    </div>

                                </div>

                            </div>

                            <div v-if="game.game.is_fast_credit" class="wrap_v2">

                                <div class="name">
                                    {{ lang.trans_150 }}
                                </div>

                                <div class="wrap">

                                    <div class="name">
                                        {{ lang.trans_492 }}
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.fast_credit_interest) }}%
                                    </div>

                                    <div class="name">
                                        {{ lang.trans_493 }}
                                    </div>

                                    <div class="name left">
                                        {{ format_number(game.game.fast_credit_limit) }} ₽
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="bottom">
                        {{ lang.trans_494 }}
                    </div>

                </div>
 
            </div>


        </div>
    </div>
</template>

<script>

import { useStore } from 'vuex'
import { computed, onMounted, onUnmounted } from 'vue'
import { format_number } from "@/api/funcs";

export default {

    setup(){

        const store     = useStore()
        const domain    = computed(() => store.getters.domain)
        const lang      = computed(() => store.getters.lang)
        const game      = computed(() => store.getters.game)


        onUnmounted(() => {
			document.querySelector('body').classList.remove('scroll_none')
		})

		onMounted(async () => {

            console.log(game.value)

            document.addEventListener('keydown', function (e) {
                if (e.keyCode === 27) {
                    close();
                }
            });

			document.querySelector('body').classList.add('scroll_none')

		})

        async function close() {
            store.dispatch('update_rules', false)
        }


        return {
            format_number,
            domain,
            close,
            lang,
            game,
        }
    }
}
</script>
<template>
    <div class="operator games">

        <div class="top">

            <div class="wrap">
                <img @click="update()" src="@/assets/images/operator/refresh.svg">

                <div class="name">
                    <router-link :to="{ name: `operator.games`}">{{ lang.trans_495 }}</router-link> - Игра "{{ game.name }}"
                </div>
            </div>

            <div class="wrap icons">

                <router-link :to="{ name: `operator.tacts` }">
                    <img src="@/assets/images/operator/play.svg">
                </router-link>

                <router-link :to="{ name: `operator.teams` }">
                    <img src="@/assets/images/operator/users.svg">
                </router-link>

                <router-link :to="{ name: `operator.games.update` }">
                    <img src="@/assets/images/operator/settings.svg">
                </router-link>

                <router-link :to="{ name: `operator.steps` }">
                    <img src="@/assets/images/operator/steps.svg">
                </router-link>

                <router-link :to="{ name: `operator.teams.v2` }">
                    <img src="@/assets/images/operator/teams.svg">
                </router-link>

                <router-link :to="{ name: `operator.analytics` }">
                    <img src="@/assets/images/operator/analitics.svg">
                </router-link>

            </div>

        </div>

        <router-view name="operator-game" />

    </div>
</template>

<script>

import { computed, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'



export default {

    setup(){


        const router    = useRouter()
        const route     = useRoute()
        const store     = useStore()
        
        const template  = computed(() => store.getters.template)
        const is_auth   = computed(() => store.getters.is_auth)
        const game      = computed(() => store.getters.game)
        const step      = computed(() => store.getters.step)
        const lang      = computed(() => store.getters.lang)

        watch(game, async () => {
            let is_active_step = false
            for(let object of game.value.steps){
                if(object.status == 'Активен'){
                    await store.dispatch('set_step', object); await store.dispatch('set_selected_step', object); is_active_step = true
                }
            }

            if(!is_active_step){
                await store.dispatch('set_step', game.value.steps[game.value.steps.length-1]); await store.dispatch('set_selected_step', game.value.steps[game.value.steps.length-1])
            }

            if(step.value.current_tact == "Ход завершен"){
                for(let tact of step.value.tacts){
                    if("Кредит" == tact.type){
                        await store.dispatch('update_tact', tact)
                    }
                }
            }else{
                for(let tact of step.value.tacts){
                    if(step.value.current_tact == tact.type){
                        await store.dispatch('update_tact', tact)
                    }
                }
            }
        })

        onMounted(async () => {

            if(!is_auth.value){
                router.push({ name: `login`, params: { template: template.value.alt_name } }); return
            }

            await store.dispatch('get_game', route.params.id)

            store.dispatch('update_where_is', 'operator')

        })

        onUnmounted(async () => {
            if(is_auth.value){
                await store.dispatch('clean_teams')
            }
        })

        async function update(){

            store.dispatch('global_block_window', true)

           //обновляем данные на странице с игрой, у пользователей
           await store.dispatch('socket_send', {
                command: "update_game", 
                gid: game.value.id,
            })

            await store.dispatch('global_block_window', false)

        }

        return { 
            game,
            lang,
            update,
        }

    }
}

</script>
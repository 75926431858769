<template>
    <div v-if="template" class="login-in">

        <form class="form">

            <div class="wrap">
                <img :src="template.poster"/>
            </div>

            <div class="input">
                <img :src="`${domain}/static/img/login_in_icon_2.png`"/>
                <input v-model="user.username" @keyup.enter="auth" type="text" :placeholder="active_lang.trans_6" />
            </div>

            <div :class="['input', {red: err}]">
                <img :src="`${domain}/static/img/login_in_icon_3.png`"/>
                <input v-model="user.password" @keyup.enter="auth" type="password" :placeholder="active_lang.trans_7" autocomplete="off"/>
                <svg v-if="err" xmlns="http://www.w3.org/2000/svg" width="16" height="16" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#a70000" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c-.006 5.52-4.48 9.994-10 10Zm-1-7v2h2v-2h-2Zm0-8v6h2V7h-2Z"/></svg>
            </div>

            <div v-if="err" class="error">
                {{ active_lang.trans_142 }}
            </div>

            <div @click='auth' class="button">

                {{ active_lang.trans_3 }}
                
                <img v-if="template.name == 'mangold-new-year'" class="mangold-new-year-v2-mishyra-5" src="https://res.cloudinary.com/shop-consoles-ru/image/upload/v1636790067/bsgames/santa_claus_PNG9989_2_cxtyvb.png">
                
            </div>


            <img v-if="template.name == 'mangold-new-year'" class="mangold-new-year-v2-mishyra-3" src="https://res.cloudinary.com/shop-consoles-ru/image/upload/v1636789651/bsgames/christmas_garland_PNG3_9_vtfsqi.png">

            <img v-if="template.name == 'mangold-new-year'" class="mangold-new-year-v2-mishyra-4" src="https://res.cloudinary.com/shop-consoles-ru/image/upload/v1636789667/bsgames/christmas_garland_PNG3_8_ouz6r3.png">


        </form>

    </div>
</template>





<script setup>

    import { computed, ref } from 'vue'
    import { onMounted } from 'vue'

    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import { useRoute } from 'vue-router'

    import { set_cookie } from "@/api/Cookie"



    const router        = useRouter()
    const route         = useRoute()
    const store         = useStore()
    const domain        = computed(() => store.getters.domain)
    const user          = computed(() => store.getters.user)
    const is_auth		= computed(() => store.getters.is_auth)
    const active_lang   = computed(() => store.getters.lang)
    const template      = computed(() => store.getters.template)
    const err           = ref(false)

    async function auth() {

        const response = await fetch(`${domain.value}/api/users/login`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: user.value.username, 
                passw: user.value.password,
            })
            
        })

        if(!response.ok){
            err.value = true; return
        }

        const body = await response.json()

        //устанавливаем куки в браузер
        set_cookie("token", body.token)

        //устанавливаем куки в хранилище
        await store.dispatch('update_cookie', `Bearer ${body.token}`)

        //обновляем юзера
        await store.dispatch('get_user')

        //забираем языки
        await store.dispatch('get_langs')

        //забираем шаблоны
        await store.dispatch('get_templates')

        //устанавливаем нужный язык
        await store.dispatch('set_lang', is_auth.value ? user.value.lang_id : false)

        //устанавливаем нужный шаблон
        await store.dispatch('set_template', is_auth.value ? user.value.template_id : route.params.template)

        //переходим в кабинет
        router.push({ name: 'cabinet' })
            
    }

    onMounted(() => {
        if(is_auth.value){ 
            router.push(`cabinet`)
        }
    })


</script>




export const update_time = ({ commit }, time) => {
    commit('update_time', time)
}



export const update_where_is = ({ commit }, str) => {
    commit('update_where_is', str)
}

export const get_forecasts = async ({ commit, getters }, id) => {

    const response = await fetch(`${getters.domain}/api/games/${id}/forecasts/`, {
        method: 'GET',
        headers: { 
            'Authorization': getters.cookie,
        },
    })

    const body = await response.json()

    commit('get_forecasts', body)
}


export const pop_up_update = ({ commit }, object) => {
    commit('pop_up_update', object)
}



export const global_block_window = ({ commit }, bool) => {

    //console.log(bool)

    commit('global_block_window', bool)

    
}

export const chart = ({ commit }, bool) => {
    commit('chart', bool)
}


export const fast_credit = ({ commit }, bool) => {
    commit('fast_credit', bool)
}

export const update_rules = ({ commit }, bool) => {
    commit('update_rules', bool)
}






export const update_err = async ({ commit }, err) => {

    //if(err.is){
        //await dispatch('add_operations', {profile: getters.user.id, event: err.name})
    //}

    //setTimeout(() => commit('update_err', {is: false, name: ""}), 3000)

    commit('update_err', err)

}

export const update_err_by_code = async ({ commit, dispatch, getters }, code) => {

    commit('update_err', {is: true, name: ""})

    code = "" + code

    if(getters.cookie){
        for (const [key, val] of Object.entries(getters.lang)) {
            if(key.includes(code)){
                await dispatch('add_operations', { profile: getters.user.id, name: val }); break
            }
        }
    }

    
    let temp_langs = null
    for(let lang of getters.langs){
        for (const [key, val] of Object.entries(lang)) {

            console.log(key.includes(code))
            
            if(key.includes(code)){

                if (temp_langs === null) {
                    temp_langs = {}
                }

                temp_langs[lang.name] = val; break

            }
        }
    }

    if(temp_langs === null){

        temp_langs = {}
        for(let lang of getters.langs){
            temp_langs[lang.name] = code
        }

    }

    commit('update_err_by_code', temp_langs)
    
}
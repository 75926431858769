<template>
    <div v-if="tabs" class="results">

        <top></top>

        <div class="active-tab">
            {{ active_tab.name }}
        </div>

        <bottom></bottom>

        <pagination></pagination>

    </div>
</template>

<script>
import { useWindowSize } from 'vue-window-size';
import { computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import Top from "@/components/game/history/Top.vue";
import Bottom from "@/components/game/history/bottom/Bottom.vue";
import Pagination from "@/components/game/history/Pagination.vue";

export default {
    components: {
        "top": Top,
        "bottom": Bottom,
        "pagination": Pagination,
    },

	setup(){
        
        const { width }     = useWindowSize()
        const store         = useStore()
        const game          = computed(() => store.getters.game)
        const tabs          = computed(() => store.getters.tabs)
        const active_tab    = computed(() => store.getters.active_tab)

        watch(width, () => store.dispatch('update_pagination_visible', width.value))

        onMounted(() => {

            //обновляем визуальную часть колонок
            store.dispatch('update_pagination_visible', width.value)

            //обновляем визуальную часть колонок
            store.dispatch('update_visible_cols')
            
        })

        watch(game, () => {
            
            //обновляем колонки
            store.dispatch('update_cols')

            //обновляем визуальную часть колонок
            store.dispatch('update_pagination_visible', width.value)

            //обновляем визуальную часть колонок
            store.dispatch('update_visible_cols')

        })

		return {
            tabs,
            active_tab,
		}

	}
}
</script>
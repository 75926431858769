<template>
    <div class="left">
        <div class="wrap">

            <div @click="qsort({name: 'Компания'})"  class="name">
                {{ alang.trans_17 }}
            </div>

            <div>
                <div v-for="object in histories" :key="object.id" :class="['desc', { active: team.id == object.id }, {'red': object.bankruptcy }]">
                    {{ object.name }}
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
	setup(){

        const store         = useStore()
        const alang         = computed(() => store.getters.lang)
        const team          = computed(() => store.getters.team)
        const histories     = computed(() => store.getters.tab_historys)

        function qsort(param){
            store.dispatch('qsort_tab_historys', param.name)
        }

		return {
            alang,
            team,
            histories,
            qsort,
		}

	}
}
</script>

//import router from '@/router'



export const update_rules = (state, bool) => {
    state.rules = bool
}


//time
export const update_time = (state, time) => {
    state.time = time
}

//where_is
export const update_where_is = (state, str) => {
    state.where_is = str
}

//forecasts
export const get_forecasts = (state, forecasts) => {
    state.forecasts = forecasts
}


export const pop_up_update = async (state, object) => {
    state.pop_up = object
}



//глобальная блокировка окна
export const global_block_window = (state, bool) => {
    state.global_block_window = bool
}

export const chart = (state, bool) => {
    state.chart = bool 
}

export const fast_credit = (state, bool) => {
    state.fast_credit = bool 
}

export const update_err = (state, err) => {
    
    state.err = err

    setTimeout(() => {
        state.err = {is: false, name: "",} 
    }, 2500)
    
}

export const update_err_by_code = (state, temp_langs) => {

    state.err = {is: true, name: temp_langs,} 

    setTimeout(() => {
        state.err = {is: false, name: "",} 
    }, 2500)
}



<template>
    <div v-for="user in users" :key="user.id" class="user">

        <div class="wrap">

            <svg @click="user_update_pop_up = true" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.4998 8L18.9998 4.5M20.9998 2.5L18.9998 4.5L20.9998 2.5ZM11.3898 12.11C11.9061 12.6195 12.3166 13.226 12.5975 13.8948C12.8785 14.5635 13.0244 15.2813 13.0268 16.0066C13.0292 16.732 12.8882 17.4507 12.6117 18.1213C12.3352 18.7919 11.9288 19.4012 11.4159 19.9141C10.903 20.4271 10.2937 20.8334 9.62309 21.1099C8.95247 21.3864 8.23379 21.5275 7.50842 21.525C6.78305 21.5226 6.06533 21.3767 5.39658 21.0958C4.72782 20.8148 4.12125 20.4043 3.61179 19.888C2.60992 18.8507 2.05555 17.4614 2.06808 16.0193C2.08061 14.5772 2.65904 13.1977 3.67878 12.178C4.69853 11.1583 6.078 10.5798 7.52008 10.5673C8.96216 10.5548 10.3515 11.1091 11.3888 12.111L11.3898 12.11ZM11.3898 12.11L15.4998 8L11.3898 12.11ZM15.4998 8L18.4998 11L21.9998 7.5L18.9998 4.5L15.4998 8Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <update v-if="user_update_pop_up" :user="user" :close="user_update_pop_up_close"></update>
        </div>

        <pop-up v-if="pop_up.active" :name="pop_up.name" :desc="pop_up.desc" :ok="pop_up.ok" :close="pop_up.close"></pop-up>

        <router-link :to="{ name: `user`, params: {id: user.id}}" class="name">
            {{ user.roles.length > 0 ? lang.trans_497 + ' -' : '' }} {{ user.name }}
        </router-link>

        <svg @click="delete_user(user)" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="url(#paint0_linear_886_8348)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 9L9 15" stroke="url(#paint1_linear_886_8348)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9 9L15 15" stroke="url(#paint2_linear_886_8348)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
            <linearGradient id="paint0_linear_886_8348" x1="25.2206" y1="-2.52411" x2="-7.08562" y2="24.8397" gradientUnits="userSpaceOnUse">
            <stop stop-color="#A70000"/>
            <stop offset="1" stop-color="#FF5050"/>
            </linearGradient>
            <linearGradient id="paint1_linear_886_8348" x1="15.9662" y1="7.64277" x2="6.27431" y2="15.8519" gradientUnits="userSpaceOnUse">
            <stop stop-color="#A70000"/>
            <stop offset="1" stop-color="#FF5050"/>
            </linearGradient>
            <linearGradient id="paint2_linear_886_8348" x1="15.9662" y1="7.64277" x2="6.27431" y2="15.8519" gradientUnits="userSpaceOnUse">
            <stop stop-color="#A70000"/>
            <stop offset="1" stop-color="#FF5050"/>
            </linearGradient>
            </defs>
        </svg>

    </div>
</template>

<script>

import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import Update from "@/components/operator/teams/users/Update.vue";
import PopUp  from '@/components/PopUp.vue'


export default {

    components: {
        'pop-up': PopUp,
        'update': Update,
    },

    props: {
        users: Object,
    },

    setup(){

        const store = useStore()
        const lang  = computed(() => store.getters.lang)
        const route = useRoute()

        const user_update_pop_up = ref(false)
        function user_update_pop_up_close(){
            user_update_pop_up.value = false
        }


        //обьект для компонента
        const pop_up  = ref({
            id: 0,
            name: '',
            desc: '',
            ok: null,
            close: () => pop_up.value.active = false,
            active: false,
        })

        //Функция для инициализации и открытия компонента
        function delete_user(user) {

            pop_up.value.name   = lang.value.trans_441
            pop_up.value.desc   = `Игрок ${user.name} будет удален навсегда.`

            pop_up.value.ok     = async () => {

                await store.dispatch('delete_users', user.id)

                await store.dispatch('get_operator_teams', route.params.id)
                
                pop_up.value.active = false
            }

            pop_up.value.active = true

        }
        
        return {
            lang,
            pop_up,
            delete_user,

            user_update_pop_up,
            user_update_pop_up_close,
        }

    }
}

</script>

<style scoped>

.operator.teams > .bottom > .team .user > svg {
cursor: pointer;
}

.operator.teams > .bottom > .team .user > .name {
font-weight: 400;
font-size: 20px;
letter-spacing: 0.02em;
color: #0944AA;
flex-grow: 1;
margin-left: 10px;
text-decoration: none;
}

.operator.teams > .bottom > .team .user > .wrap {
background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
border-radius: 4px;
padding: 5px;
display: flex;
cursor: pointer;
}

</style>
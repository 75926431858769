<template>

    <div class="wrap">

        <div @click="close_chart()" class="back"></div>

        <div class="chart">

            <h2 @click="close_chart()">
                {{ lang.trans_451 }}
                <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg>
            </h2>

            <div class="wrap">
                <div v-for="tab in filter_tabs" :key="tab.id" @click="select_tab(tab)" :class="['name', {active: tab.active}]">{{ get_lang_name(lang, tab.alt_name) }}</div>
            </div>

            <apexchart v-if="historys" width="100%" height="450" type="line" :options="options" :series="historys"></apexchart>

        </div>

    </div>

</template>

<script>

import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { ref } from 'vue'
import { format_number, get_lang_name, requests } from "@/api/funcs"

export default {

    setup() {

        const store         = useStore()
        const domain        = computed(() => store.getters.domain)
        const cookie        = computed(() => store.getters.cookie)
        const game          = computed(() => store.getters.game)
        const lang          = computed(() => store.getters.lang)
        const historys      = ref([])

        const options = ref({

            stroke: {
                curve: 'smooth',
            },

            chart: {

                id: 'chart',

                toolbar: {
                    show: true,
                },

                animations: {
                    enabled: false,
                },

            },

            dataLabels: {
                enabled: false
            },

            yaxis: {
                labels: {
                    formatter: function(val) {
                        return format_number(val);
                    },
                },
            },

        })

        const tabs = ref([

            { id: 1, name: 'Cчет',              alt_name: 254,  active: true,   variant: false, hidden_reiting: false},
            { id: 2, name: 'Кредит',            alt_name: 22,   active: false,  variant: false, hidden_reiting: false},
            { id: 3, name: 'Сырье',             alt_name: 255,  active: false,  variant: false, hidden_reiting: false},
            { id: 4, name: 'Продукция',         alt_name: 25,   active: false,  variant: false, hidden_reiting: false},
            { id: 5, name: 'Премиум продукция', alt_name: 256,  active: false,  variant: true, hidden_reiting: false},
            { id: 6, name: 'Фабрики',           alt_name: 76,   active: false,  variant: false, hidden_reiting: false},
            { id: 7, name: 'Авто фабрики',      alt_name: 257,  active: false,  variant: false, hidden_reiting: false},
            { id: 8, name: 'Рейтинг',           alt_name: 78,   active: false,  variant: false, hidden_reiting: true},

        ])

        async function select_tab(c_tab) {

            for(let tab of tabs.value){
                tab.active = false
            }

            c_tab.active = true

            if(c_tab.id < 6){
                await SeriesGetByStep(c_tab.id)
            }else{
                await SeriesGetByGame(c_tab.id)
            }
            
        }

        const filter_tabs = computed(() => {

            let result = game.value.game.variant ? tabs.value : tabs.value.filter((o) => !o.variant)

            result = game.value.game.hidden_reiting ? result : result.filter((o) => !o.hidden_reiting)

            return result

        })

        async function close_chart() {
            store.dispatch('chart', false)
        }

        async function SeriesGetByGame(id){
            const [ err, code, body ] = await requests(`${domain.value}/api/chart/games/${game.value.game.id}/chart/${id}`, 'GET', cookie.value)

            if(err){
                store.dispatch('update_err_by_code', code); return
            }

            historys.value = body
        }

        async function SeriesGetByStep(id){
            const [ err, code, body ] = await requests(`${domain.value}/api/chart/steps/${game.value.step.id}/chart/${id}`, 'GET', cookie.value)

            if(err){
                store.dispatch('update_err_by_code', code); return
            }

            historys.value = body
        }

        onMounted(async () => {
            await SeriesGetByStep(tabs.value[0].id)
        })


        return {
            lang,
            options,
            filter_tabs,
            select_tab,
            get_lang_name,
            close_chart,
            historys,
        }

    }
}
</script>

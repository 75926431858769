<template>
    <div v-if="user && is_visible()" class="operator profiles">
        <div class="form">

            <div>

                <div class="name">Просмотр профиля</div>

                <div class="wrap">

                    <div class="left">
                        <img :src="domain + user.avatar + '_750.jpg'" />
                    </div>

                    <div class="right">

                        <div class="name">
                            {{ user_is_operator ? 'оператор' : 'пользователь' }}
                        </div>

                        <div class="desc">
                            {{ user.first_name }}
                            <br>
                            {{ user.last_name }}
                        </div>

                        <div class="desc-2">
                            {{ user.username }}
                        </div>

                    </div>
                </div>

                <div class="wrap">
                    <div class="left">
                        страна
                    </div>

                    <div class="right">
                        {{ user.country }}
                    </div>
                </div>

                <div class="wrap">
                    <div class="left">
                        день рождения
                    </div>

                    <div class="right">
                        {{ user.birthday }}
                    </div>
                </div>

                <div class="wrap">
                    <div class="left">
                        пол
                    </div>

                    <div class="right">
                        {{ user.gender }}
                    </div>
                </div>

                <div class="wrap">
                    <div class="left">
                        о себе
                    </div>

                    <div class="right">
                        {{ user.info }}
                    </div>
                </div>
                
            </div>

        </div>
    </div>
</template>

<script>

import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { requests } from "@/api/funcs";


export default {

    setup(){

        const route                     = useRoute()
        const store                     = useStore()
        const domain                    = computed(() => store.getters.domain)
        const cookie                    = computed(() => store.getters.cookie)
        const lang                      = computed(() => store.getters.lang)
        const current_user              = computed(() => store.getters.user)
        const current_user_is_operator  = computed(() => store.getters.is_operator)

        const user                      = ref(null)

        const groups                    = ref(null)
        const selected_groups           = ref([])
        const user_is_operator          = computed(() => selected_groups.value ? selected_groups.value.filter((o) => o.name == 'operator').length > 0 ? true : false : false)


        function is_visible(){

            if(current_user.value.is_superuser){
                return false
            }

            if(current_user.value.id == user.value.id){
                return false
            }

            if(current_user_is_operator.value && (current_user.value.profile.id == user.value.creator)){
                return false
            }

            return true
        }


        onMounted(async () => {

            let [ err, code, body ] = await requests(`${domain.value}/api/users/${route.params.id}`, 'GET', cookie.value)

            if(err){
                store.dispatch('update_err_by_code', code); return
            }

            user.value = body
        })

        return { 
            domain,
            lang, 
            current_user,
            current_user_is_operator,

            user,
            user_is_operator,
            
            groups,
            selected_groups,

            is_visible,

        }

    }
}

</script>


<style scoped>

.operator.profiles {
    max-width: 840px;
    width: 100%;
}

.operator.profiles > .form {

}

.operator.profiles > .form > div {
    background: white;
    padding: 1em;
    border-radius: 5px;
    margin-top: 10px;
}

.operator.profiles > .form > div > .wrap {
    display: flex;
    border-bottom: 1px solid #5E5E5E;
    padding-bottom: 15px;
}

.operator.profiles > .form > div > .wrap > .left {
    margin-right: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #5E5E5E;
    min-width: 125px;
    display: flex;
}

.operator.profiles > .form > div > .wrap > .left > img {
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 3em;
}


.operator.profiles > .form > div > .wrap > .right {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #222222;
}

.operator.profiles > .form > div > .wrap > .right > .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #0944AA;
    margin-bottom: 10px;
}

.operator.profiles > .form > div > .wrap > .right > .desc {
    font-weight: 700;
    font-size: 21px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #222222;
}

.operator.profiles > .form > div > .wrap > .right > .desc-2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #222222;
    margin-top: 2.5px;
}


</style>
<template>

    <div v-if="is_visible()" class="operator profiles">

        <div class="form">

            <div>

                <div class="name">Редактирование профиля - {{ user.name }}</div>

                <form-image></form-image>

                <input-text name="Логин" v-model:model="user.name" placeholder="Логин" :max="25" :event="update" :is_call="false"></input-text>

                <input-text name="Электронная почта" v-model:model="user.email" placeholder="Электронная почта" :max="255" :event="update" :is_call="false"></input-text>

                <input-text name="Номер телефона" v-model:model="user.phone" placeholder="Номер телефона" :max="255" :event="update" :is_call="false"></input-text>

                <div @click="update_pop_up = true" class="btn">изменить пароль</div>

                <user-password-update v-if="update_pop_up" :event="update_password" :close="close" :curr_pass="user.passw"></user-password-update>

            </div>

            <div v-if="current_user.is_superuser || current_user_is_operator && current_user.id != user.id">

                <div class="name">Настройки интерфейса</div>
                
                <!-- шаблоны -->
                <input-select-v2 name="Выбранный стиль" v-model:model="user.template_id" :list="selected_templates"></input-select-v2>

                <input-select-multiple-v2 name="Доступные стили" :list="templates" :selected="selected_templates" :append="append_template" :remove="remove_template"></input-select-multiple-v2>

                <!-- языки -->
                <input-select-v2 name="Выбранный язык" v-model:model="user.lang_id" :list="selected_langs"></input-select-v2>

                <input-select-multiple-v2 name="Доступные языки" :list="langs" :selected="selected_langs" :append="append_langs" :remove="remove_langs"></input-select-multiple-v2>

            </div>

            <div>

                <div class="name">Редактирование профиля</div>

                <input-text name="Имя" v-model:model="user.first_name" placeholder="Имя" :max="25"  :event="update" :is_call="false"></input-text>

                <input-text name="Фамилия" v-model:model="user.last_name" placeholder="Фамилия" :max="25"  :event="update" :is_call="false"></input-text>
                
                <text-area name="О себе" v-model:model="user.info" placeholder="О себе" :max="200"  :event="update" :is_call="false"></text-area>

                <input-text name="Пол" v-model:model="user.gender" placeholder="Пол" :max="25"  :event="update" :is_call="false"></input-text>

                <input-text name="Страна" v-model:model="user.country" placeholder="Страна" :max="25"  :event="update" :is_call="false"></input-text>

                <input-text name="День рождения" v-model:model="user.birthday" placeholder="День рождения" :max="25"  :event="update" :is_call="false"></input-text>

            </div>


            <div v-if="current_user.is_superuser && current_user.id != user.id">

                <div class="name">Настройки прав</div>

                <!-- групы -->
                <input-select-multiple name="Доступные роли" :list="roles" :selected="selected_roles" :append="append_roles" :remove="remove_roles"></input-select-multiple>

                <input-bool name="Суперпользователь" v-model:model="user.is_superuser"></input-bool>

            </div>


            <div class="btm">
                <div @click="update(user.passw)" class="send">Сохранить</div>
                <!-- <div class="cancel">{{ lang.trans_368 }}</div> -->
            </div>
           
        </div>

    </div>

</template>

<script>

import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { requests } from "@/api/funcs";


import Image from "@/components/form/Image.vue";
import InputText  from '@/components/form/InputText.vue'
import TextArea  from '@/components/form/TextArea.vue'
import InputBool  from '@/components/form/InputBool.vue'

import UserPasswordUpdate from "@/components/operator/UserPasswordUpdate.vue";
import InputSelect_v2  from '@/components/form/InputSelect_v2.vue'
import InputSelectMultiple  from '@/components/form/InputSelectMultiple.vue'
import InputSelectMultipleV2  from '@/components/form/InputSelectMultipleV2.vue'

export default {

    components: {
        "form-image":               Image,
        "input-text":               InputText,
        "text-area":                TextArea,
        "input-bool":               InputBool,
        "user-password-update":     UserPasswordUpdate,
        "input-select-v2":          InputSelect_v2,
        "input-select-multiple":    InputSelectMultiple,
        "input-select-multiple-v2":    InputSelectMultipleV2,
    },

    setup(){

        const route                     = useRoute()
        const store                     = useStore()
        const domain                    = computed(() => store.getters.domain)
        const cookie                    = computed(() => store.getters.cookie)
        const lang                      = computed(() => store.getters.lang)
        //const url                       = computed(() => store.getters.url)
        const current_user              = computed(() => store.getters.user)
        const current_user_is_operator  = computed(() => store.getters.is_operator)


        const user                  = ref(null)
        const templates             = ref([])
        const selected_templates    = ref([])

        const langs                 = ref([])
        const selected_langs        = ref([])

        const roles                = ref([])
        const selected_roles       = ref([])



        function is_visible(){

            if(user.value){

                if(current_user.value.is_superuser){
                    return true
                }

                if(current_user.value.id == user.value.id){
                    return true
                }

                if(current_user_is_operator.value && current_user.value.id == user.value.creator){
                    return true
                }

            }

            return false
        }


        function remove_roles(id){
            selected_roles.value = selected_roles.value.filter(o => o.id != id); user.value.roles = user.value.roles.filter(o => o.id != id)
        }

        function append_roles(id){

            for(let selected_group of selected_roles.value){
                if(selected_group.id == id){
                    return
                }
            }

            for(let group of roles.value){
                if(group.id == id){
                    selected_roles.value.push(group); user.value.roles.push(group)
                }
            }

        }

        function remove_langs(id){
            selected_langs.value = selected_langs.value.filter(o => o.id != id); user.value.langs = user.value.langs.filter(o => o.id != id)
        }

        function append_langs(id){

            for(let selected_lang of selected_langs.value){
                if(selected_lang.id == id){
                    return
                }
            }

            for(let lang of langs.value){
                if(lang.id == id){
                    selected_langs.value.push(lang); user.value.langs.push(lang)
                }
            }

        }

        function remove_template(id){
            selected_templates.value = selected_templates.value.filter(o => o.id != id); user.value.templates = user.value.templates.filter(o => o.id != id)
        }


        function append_template(id){

            for(let selected_template of selected_templates.value){
                if(selected_template.id == id){
                    return
                }
            }

            for(let template of templates.value){
                if(template.id == id){
                    selected_templates.value.push(template); user.value.templates.push(template)
                }
            }

        }
        
        const update_pop_up = ref(false)
        async function close(){
            update_pop_up.value = false
        }

        onMounted(async () => {

            //user
            let [ err, code, body ] = await requests(`${domain.value}/api/users/${route.params.id}`, 'GET', cookie.value)

            if(err){
                store.dispatch('update_err_by_code', code); return
            }

            user.value = body

            await store.dispatch('update_url', user.value.avatar)

            //templates all
            ;[ err, code, body ] = await requests(`${domain.value}/api/templates`, 'GET', cookie.value)

            if(err){
                store.dispatch('update_err_by_code', code); return
            }

            templates.value = body

            for(let selected_template of user.value.templates){
                for(let template of templates.value){
                    if(selected_template.id == template.id){
                        selected_templates.value.push(template)
                    }
                }
            }


            //langs all
            [ err, code, body ] = await requests(`${domain.value}/api/langs`, 'GET', cookie.value)

            if(err){
                store.dispatch('update_err_by_code', code); return
            }

            langs.value = body

            for(let selected_lang of user.value.langs){
                for(let lang of langs.value){
                    if(selected_lang.id == lang.id){
                        selected_langs.value.push(lang)
                    }
                }
            }


            //roles all
            [ err, code, body ] = await requests(`${domain.value}/api/roles`, 'GET', cookie.value)

            if(err){
                store.dispatch('update_err_by_code', code); return
            }

            roles.value = body

            for(let selected_group of user.value.roles){
                for(let group of roles.value){

                    if(selected_group.id == group.id){
                        selected_roles.value.push(group)
                    }
                }
            }

        })


        async function update_password(password){

            user.value.passw = password

            store.dispatch('global_block_window', true)

            let [ err, code ] = await requests(`${domain.value}/api/users/${user.value.id}/password`, 'PUT', cookie.value, user.value)

            if(err){
                store.dispatch('update_err_by_code', code); store.dispatch('global_block_window', false); return
            }

            store.dispatch('update_err_by_code', "499");

            store.dispatch('global_block_window', false)

        }

        async function update(){

            store.dispatch('global_block_window', true)

            let [ err, code, body ] = await requests(`${domain.value}/api/users/${user.value.id}`, 'PUT', cookie.value, user.value)

            if(err){
                store.dispatch('update_err_by_code', code); store.dispatch('global_block_window', false); return
            }

            //забираем юзера
            user.value = body

            //устанавливаем нужный язык
            await store.dispatch('set_lang', user.value.lang_id)

            //устанавливаем нужный шаблон
            await store.dispatch('set_template', user.value.template_id)

            store.dispatch('global_block_window', false)

            store.dispatch('update_err_by_code', 453)

        }

        return { 
            lang, 
            current_user,
            current_user_is_operator,

            user, 
            update,
            update_pop_up, 
            close, 
            update_password,
            
            templates,
            selected_templates,
            append_template,
            remove_template,

            langs,
            selected_langs,
            append_langs,
            remove_langs,

            roles,
            selected_roles,
            remove_roles,
            append_roles,

            is_visible,
        }

    }
}

</script>


<style scoped>

.operator.profiles {
    max-width: 768px;
}

.operator.profiles > .form > div {
    background: white;
    padding: 1em;
    border-radius: 5px;
    margin-top: 10px;
}

.operator.profiles > .form > div > .btn {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
    border-radius: 4px;
    max-width: 135px;
    margin: 0 auto;
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
}

.operator.profiles > .form > .btm {
    display: flex;
    background: none;
    padding: 1em 0;
}

.operator.profiles > .form > .btm > .send {
    background: linear-gradient(229.73deg, #00A76B -19.09%, #50FFAB 131.11%);
    border-radius: 8px;
    padding: 15px;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    margin-right: 10px;
    flex-grow: 1;
    text-align: center;
    cursor: pointer;
}

.operator.profiles > .form > .btm > .cancel {
    background: linear-gradient(229.73deg, #A70000 -19.09%, #FF5050 131.11%);
    border-radius: 8px;
    padding: 15px;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    min-width: 300px;
    text-align: center;
    cursor: pointer;
}



</style>

<template>
    <div class="image">

        <img v-if="url" :src="domain + url + '_750.jpg'" />

        <div class="right">

            <label>{{ lang.trans_371 }}</label>

            <div @click="image_input.click()" class="wrap">

                <div>

                    {{ upload_image.name }}

                    <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><path fill="currentColor" d="M22.95,9.6a1,1,0,0,0-1.414,0L10.644,20.539a5,5,0,1,1-7.072-7.071L14.121,2.876a3,3,0,0,1,4.243,4.242L7.815,17.71a1.022,1.022,0,0,1-1.414,0,1,1,0,0,1,0-1.414l9.392-9.435a1,1,0,0,0-1.414-1.414L4.987,14.882a3,3,0,0,0,0,4.243,3.073,3.073,0,0,0,4.243,0L19.778,8.532a5,5,0,0,0-7.071-7.07L2.158,12.054a7,7,0,0,0,9.9,9.9L22.95,11.018A1,1,0,0,0,22.95,9.6Z"/></svg>
            
                </div>

                <input type="file" ref="image_input" @change="select_file($event)" class="hidden">

            </div>

            <div class="name">
                {{ lang.trans_372 }}
            </div>

        </div>

    </div>
</template>

<script>

import { ref, computed } from "vue"
import { useStore } from 'vuex'

export default {
	setup(){

        const store         = useStore()
        const domain        = computed(() => store.getters.domain)
        const lang          = computed(() => store.getters.lang)
        const upload_image  = computed(() => store.getters.upload_image)
        const url           = computed(() => store.getters.url)
        const image_input   = ref(null)

        async function select_file(e){

            await store.dispatch('upload_image', {
                name:   e.target.files[0].name,
                image:  e.target.files[0],
            })

            await store.dispatch('upload_image_server')

        }


        return {
            lang,
            domain,
            url,
            select_file,
            image_input,
            upload_image,
        }
    
	}
}

</script>

<style>

.image {
    margin-bottom: 1em;
}

@media (min-width: 360px){
    .image {
        display: flex;
        align-items: flex-start;
    }
}

.image > img {
    border-radius: 5px;
    object-fit: cover;
    cursor: pointer;
}

@media (min-width: 360px){
    .image > img {
        max-width: 80px;
        height: 60px;
        margin-right: 5px;
    }
}

.image > .right {
    margin-top: 5px;
    position: relative;
    flex-grow: 1;
}

@media (min-width: 360px){
    .image > .right {
        margin-top: 0;
    }
}


.image > .right > .wrap {
    cursor: pointer;
}

.image > .right > .wrap > div {
    margin: 0;
    width: 100%;
    padding: 20px 15px 20px;
    border: 1px solid rgba(0,0,0,.38);
    border-radius: 5px;
    color: #adadad;
    font-size: .8em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    word-break: break-all;
}

.image > .right > .wrap > div > svg {
    width: 1.5em;
    height: 1.5em;
}

.image > .right > .wrap > input {
    margin: 0;
    width: 100%;
    padding: 20px 15px 20px;
    border: 1px solid rgba(0,0,0,.38);
    border-radius: 5px;
}

.image > .right > .wrap > input::placeholder {
    color: #adadad;
    font-weight: 400;
    letter-spacing: .1px;
    font-family: 'Gilroy';
}

.image > .right > .name {
    font-size: 12px;
    color: rgba(0,0,0,.6);
    margin: 10px 0 0 0;
}

.image .right > label {
    left: 0.8em;
    right: auto;
    position: absolute;
    background: white;
    color: rgba(0,0,0,.6);
    font-size: .85em;
    top: -0.6em;
    padding: 0 0.35em;

    max-width: 205px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 768px) {
    .image .right > label {
        max-width: 100%;
    }
}
</style>
export function trackWindowWidth(callback) {
    let timeoutId = null;

    const throttledCallback = () => {
        if (!timeoutId) {
            timeoutId = setTimeout(() => {
                callback(window.innerWidth);
                timeoutId = null;
            }, 200);
        }
    };

    throttledCallback();
    window.addEventListener('resize', throttledCallback);

    // Возвращаем функцию, которая удалит слушатель
    return () => {
        window.removeEventListener('resize', throttledCallback);
    };
}

<template>
    <div class="input int">

        <label>{{ name }}</label>

        <div class="wrap">
            <input type='text' @input="test($event.target)" :value="model" :placeholder="placeholder" :readonly="readonly"/>
        </div>

    </div>
</template>

<script>

export default {

    props: {
        name: String,
        model: Number,
        max: Number,
        placeholder: String,
        event: Function,
        readonly: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["update:model"],
    
	setup(props, ctx){

        async function test(target){
            
            let result = parseInt(target.value, 10)
            if(isNaN(result) || result < 0){
                result = 0
            }

            if(result >= 2147483647){
                result = 2147483647
            }

            target.value = result

            ctx.emit('update:model', result)

            await props.event()
        }

        return { 
            test,
        }
    }
}

</script>

<style scoped>

.input.int {
    position: relative;
    margin-bottom: 1.25em;
    border-radius: 5px;
}

.input.int:last-of-type {
    margin-bottom: 0;
}


.input.int > label {
    left: 0.8em;
    right: auto;
    position: absolute;
    background: white;
    color: #0944AA;
    font-size: .85em;
    top: -0.6em;
    padding: 0 0.35em;

    max-width: 205px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 768px) {
    .input.int > label {
        max-width: 100%;
    }
}

.input.int > .wrap {
    border: 1px solid #0944AA;
    border-radius: 8px;
    padding: .8em .95em;
    display: flex;
    align-items: center;
}

.input.int > .wrap > input[type="text"] {
    max-width: 100%;
    margin: 0;
    border: none;
    outline: none;
    width: 100%;
    font-size: .9em;
}

.input.int > .wrap > .length {
    font-weight: 400;
    font-size: .9em;
    color: #0944AA;
    opacity: 0.5;
}

</style>

<template>
    <div class="pop_up">

        <div @click="close" class="back"></div>

        <div class="wrap">

            <div class="name">{{ lang.trans_443 }}</div>

            <div class="form">

                <input-text :name="lang.trans_6" v-model:model="form.name" :placeholder="lang.trans_6" :max="40" :event="add" :is_call="false"></input-text>

                <input-password :name="lang.trans_7" v-model:model="form.passw" :placeholder="lang.trans_7" :max="40" :event="add"></input-password>

            </div>

            <div class="wrap">
                <div @click="add" @keyup.enter="add" class="btn ok">{{ lang.trans_226 }}</div>
                <div @click="close" class="btn close">{{ lang.trans_368 }}</div>
            </div>

        </div>

    </div>
</template>

<script>

import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import InputText  from '@/components/form/InputText.vue'
import InputPassword  from '@/components/form/InputPassword.vue'

export default {

    components: {
        "input-text": InputText,
        "input-password": InputPassword,
    },

    props: {
        close: Function,
        tid: Number,
    },

    setup(props){

        const route     = useRoute()
        const store     = useStore()
        const domain    = computed(() => store.getters.domain)
        const lang      = computed(() => store.getters.lang)

        const form = ref({
            name: '',
            passw: '',
        })
        
        async function add(){

            store.dispatch('global_block_window', true)

            const response = await fetch(`${domain.value}/api/users/games/${route.params.id}/teams/${props.tid}`, {
                method: 'POST',
                headers: {
                    'Content-Type':'application/json',
                },
                body: JSON.stringify(form.value)
            })

            if(!response.ok){
                const body = await response.json(); store.dispatch('update_err_by_code', body.code); store.dispatch('global_block_window', false); return
            }

            await store.dispatch('get_operator_teams', route.params.id)

            props.close()
    
            store.dispatch('global_block_window', false)

        }


        return { lang, form, add, }

    }
}

</script>

<style scoped>
.pop_up {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop_up > .back {
    background: black;
    opacity: .5;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.pop_up > .wrap {
    background: white;
    z-index: 1;
    padding: 1.5em;
    border-radius: 15px;
    max-width: 650px;
    width: 100%;
    font-size: calc(15px + 6 * ((100vw - 320px) / 680));
}

@media (min-width: 500px){
    .pop_up > .wrap {
        font-size: 18px;
    }
}

.pop_up > .wrap > .name {
    font-weight: 500;
    font-size: 1.925em;
    letter-spacing: 0.02em;
    color: #0944AA;
    margin-bottom: 0.5em;
}

@media (min-width: 500px){
    .pop_up > .wrap > .wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1em;
        margin-top: 1em;
    }
}

.pop_up > .wrap > .wrap > .btn {
    border-radius: 4px;
    color: white;
    padding: .9em;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: .9em;
    text-align: center;
    cursor: pointer;
}

@media (min-width: 500px){
    .pop_up > .wrap > .wrap > .btn {
        width: 100%;
        margin: 0 auto;
    }
}

.pop_up > .wrap > .wrap > .btn.ok {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
}

.pop_up > .wrap > .wrap > .btn.close {
    background: linear-gradient(229.73deg, #A70000 -19.09%, #FF5050 131.11%);
}

</style>


export const update_tabs = async ({ commit }, tabs) => {
    commit('update_tabs', tabs)
}

//в зависимости от разрешения экрана устанавливаем конечную переменную диапазона
export const update_pagination_visible = async ({ commit }, width) => {

    if(width > 320)   commit('update_stop_pagination', 1)
    if(width > 500)   commit('update_stop_pagination', 2)
    if(width > 650)   commit('update_stop_pagination', 3)
    if(width > 800)   commit('update_stop_pagination', 4)
    if(width > 950)   commit('update_stop_pagination', 5)
    if(width > 1100)  commit('update_stop_pagination', 6)
    if(width > 1250)  commit('update_stop_pagination', 7)
    if(width > 1440)  commit('update_stop_pagination', 8)

    commit('update_visible_cols')

}

//обновляем статус у всплывающего окна
export const update_pop_up = async ({ commit }, bool) => {
    commit('update_pop_up', bool)
}

//сортировка истории таба при клике
export const qsort_tab_historys = async ({ commit }, name) => {
    commit('qsort_tab_historys', name)
}

//обновляем видимые колонки
export const update_visible_cols = async ({ commit }) => {
    commit('update_visible_cols')
}

//обновляем колонки
export const update_cols = async ({ commit }) => {
    commit('update_cols')
}

//устанавливаем нужный таб
export const select_tab = async ({ commit }, name) => {
    commit('select_tab', name)
}

//смещаем на единицу вперед видимую часть колонок
export const update_right_pagination = async ({ dispatch, getters }) => {

    if(getters.pagination.stop <= getters.cols.length){
        dispatch('update_start_pagination', getters.pagination.start + 1)
        dispatch('update_stop_pagination', getters.pagination.stop + 1)
    }

    dispatch('update_visible_cols')
}

//смещаем на единицу назад видимую часть колонок
export const update_left_pagination = async ({ dispatch, getters }) => {

    if(getters.pagination.start > 0){
        dispatch('update_start_pagination', getters.pagination.start - 1)
        dispatch('update_stop_pagination', getters.pagination.stop - 1)
    }

    dispatch('update_visible_cols')
}

//обновляем начальную переменную диапазона
export const update_stop_pagination = async ({ commit }, int) => {
    commit('update_stop_pagination', int)
}

//обновляем конечную переменную диапазона
export const update_start_pagination = async ({ commit }, int) => {
    commit('update_start_pagination', int)
}
<template>
    <div class="top">
        <div v-for="(tab, i) in tabs" :key="tab.id" :class="['tab', {'first': i == 0}, {'last': i == (tabs.length-1)}]">
            <div @click="select_tab(tab)" :class="['wrap', {'active': tab.active},]">
                <div class="icon" v-html="tab.icon"></div>
                <div class="name">
                    {{ wrap_tab(tab) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'
import { get_lang_name } from "@/api/funcs";

export default {

	setup(){

        const store = useStore()
        const alang = computed(() => store.getters.lang)
        const tabs  = computed(() => store.getters.tabs)

        function select_tab(object) {
            
            //устанавливаем активный таб
            store.dispatch('select_tab', object.name)

            //обновляем колонки
            store.dispatch('update_cols')

            //обновляем пагинацию
            store.dispatch('update_visible_cols')

            for(let tab of tabs.value){
                if(tab.active){
                    store.dispatch('update_tab_historys', tab.histories)
                }
            }

        }

        function wrap_tab(tab){
            if(tab.name == "Начало хода"){
                return get_lang_name(alang.value, 338)
            }else{
                return get_lang_name(alang.value, tab.alt_name)
            }
        }

		return {
            get_lang_name,
            alang,
            tabs,
            select_tab,
            wrap_tab,
		}

	}
}
</script>
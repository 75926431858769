<template>

    <my-header></my-header>

    <div v-if="template" :class="['wrap', template.name]">

        <img v-if="template.name == 'mangold-new-year'" class="mangold-new-year-v2-mishyra-1" src="https://res.cloudinary.com/shop-consoles-ru/image/upload/v1636788620/bsgames/bauble_PNG238_2_poisqo.png">

        <img v-if="template.name == 'mangold-new-year'" class="mangold-new-year-v2-mishyra-2" src="https://res.cloudinary.com/shop-consoles-ru/image/upload/v1636788618/bsgames/christmas_PNG17209_1_hptctg.png">

        <img v-if="template.name == 'mangold-new-year'" class="mangold-new-year-v2-mishyra-2-v2" src="https://res.cloudinary.com/shop-consoles-ru/image/upload/v1636788618/bsgames/christmas_PNG17209_1_hptctg.png">

        <div :class="['body', template.name]">
 
            <div v-if="is_auth" class="panel">

                <div class="country">

                    <div @click="templates_swtch_flag = !templates_swtch_flag" class="name">
                        {{ template.human_name }}
                    </div>

                    <div @click="templates_swtch_flag = !templates_swtch_flag" class="wrap">
                       <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
                    </div>

                    <div v-if="templates_swtch_flag" class="langs">
                        <div 
                            v-for="object in templates" 
                            :key="object.id" 
                            @click="templates_swtch(object.id)" 
                            :class="['lang', {active: template.id == object.id}]">

                            {{ object.human_name }}
                        </div>
                    </div>

                </div>

                <div v-if="templates_swtch_flag || langs_swtch_flag" @click="close_windows()" class="back"></div>

                <div class="country">

                    <img @click="langs_swtch_flag = !langs_swtch_flag" :src="active_lang.icon" />

                    <div @click="langs_swtch_flag = !langs_swtch_flag" class="name">
                        {{ active_lang.name }}
                    </div>

                    <div @click="langs_swtch_flag = !langs_swtch_flag" class="wrap">
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
                    </div>

                    <div v-if="langs_swtch_flag" class="langs">
                        <div v-for="lang in langs" :key="lang.id" @click="langs_swtch(lang.id)" class="lang">
                            {{ lang.name }}
                        </div>
                    </div>

                </div>
            </div>

            <router-view/>
        </div>
    </div>

    <div class="footer">
        <div class="wrap">2019-2023 BSGames - Business Simulation Games</div>
        <div class="wrap">V4.2 Dev. <a href="https://freelance.ru/python007">Python007</a></div>
        <div class="wrap">Всего пользователей - {{ count_users }}</div>
    </div>

    <div v-if="global_block_window" class="blocked">
        <div class="back"></div>
        <div class="name">Подождите, идет загрузка данных...</div>
        <div class="loading-wrap">
            <div class="triangle1"></div>
            <div class="triangle2"></div>
            <div class="triangle3"></div>
        </div>
    </div>

    <Transition>
        <virtual-assistant v-if="is_auth && assistant"></virtual-assistant>
    </Transition>

    <Transition>
        <virtual-assistant-v1 v-if="is_auth && !assistant"></virtual-assistant-v1>
    </Transition>
        

</template>

<script>

import Head from "@/components/Head.vue";
import VirtualAssistant from '@/components/VirtualAssistant.vue'
import VirtualAssistantV1 from '@/components/VirtualAssistantV1.vue'

import { useStore } from 'vuex'
import { computed, ref, onBeforeMount, watch } from 'vue'
import { useRoute } from 'vue-router'
import { get_cookie } from "@/api/Cookie";



export default {

    components: {
        "my-header": Head,
        'virtual-assistant': VirtualAssistant,
        'virtual-assistant-v1': VirtualAssistantV1,
    },

    setup() {

        const store                 = useStore()
        const route                 = useRoute()

        const user                  = computed(() => store.getters.user)
        const is_auth               = computed(() => store.getters.is_auth)
        const global_block_window   = computed(() => store.getters.global_block_window)
        const socket_open           = computed(() => store.getters.socket_open)
        const assistant             = computed(() => store.getters.assistant)
        const count_users           = computed(() => store.getters.count_users)
        const template              = computed(() => store.getters.template)
        const templates             = computed(() => store.getters.templates)
        const templates_swtch_flag  = ref(false)
        const langs                 = computed(() => store.getters.langs)
        const active_lang           = computed(() => store.getters.lang)
        const langs_swtch_flag      = ref(false)



        //первичная инициализация
        onBeforeMount(async () => {

            //устанавливаем куки только если они есть...
            if(get_cookie('token')){
                store.dispatch('update_cookie', `Bearer ${get_cookie('token')}`)  
            }

            //забираем юзера
            await store.dispatch('get_user')

            //забираем языки
            await store.dispatch('get_langs')

            //забираем шаблоны
            await store.dispatch('get_templates')

            //устанавливаем нужный язык
            await store.dispatch('set_lang', is_auth.value ? user.value.lang_id : false)

            //устанавливаем нужный шаблон
            await store.dispatch('set_template', is_auth.value ? user.value.template_id : route.params.template)

        })

        function close_windows(){
            langs_swtch_flag.value = false
            templates_swtch_flag.value = false
        }

        //переключаем язык
        async function langs_swtch(id){

            store.dispatch('set_lang', id)

            if(is_auth.value){

                user.value.lang_id = id

                await store.dispatch('update_user')
            }
            

            langs_swtch_flag.value = false
            
        }

        //ставим наблюдателя за юзером
        watch(active_lang, async () => {
            if(is_auth.value && !socket_open.value){
                await store.dispatch('socket_create')
            }
        })
        
        //переключаем шаблон
        async function templates_swtch(id){

            store.dispatch('set_template', id)

            if(is_auth.value){
                
                user.value.template_id = id

                await store.dispatch('update_user')
            }

            templates_swtch_flag.value = false

        }

        watch(template, () => document.body.className = template.value.alt_name)

        return {
            assistant,
            global_block_window,
            user,
            is_auth,
            close_windows,
            
            langs,
            active_lang,
            langs_swtch_flag,
            langs_swtch,

            template,
            templates,
            templates_swtch_flag,
            templates_swtch,
            count_users,

        }
    }
}
</script>

<style>

.v-enter-active, .v-leave-active {
  transition: opacity 250ms linear;
}

.v-enter-from, .v-leave-to {
  opacity: 0;
}

.err {
    position: fixed;
    z-index: 9999;
    left: 0;
    right: 0;
    background: #ca3939;
    bottom: 0;
    padding: 1em;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.err > .name {
    text-align: center;
    letter-spacing: .3px;
}

.err > svg {
    width: 1.25em;
    height: 1.25em;
    margin-left: .5em;
}



.blocked {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.blocked > .back {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    opacity: .7;
}

.blocked > .name {
    color: white;
    z-index: 1;
    margin-bottom: 1em;
    text-align: center;
}

.loading-wrap {
    width: 60px;
    height: 60px;
    background: #777;
    -webkit-animation: rotation ease-in-out 2s infinite;
    animation: rotation ease-in-out 2s infinite;
    border-radius: 30px; 
}

.triangle1, .triangle2, .triangle3 {
    border-width: 0 20px 30px 20px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: #67cbf0;
    height: 0; width: 0;
    position: absolute;
    left: 10px; top: -10px;
    -moz-animation: fadecolor 2s 1s infinite;
    -webkit-animation: fadecolor 2s 1s infinite;
    -ms-animation: fadecolor 2s 1s infinite;
    animation: fadecolor 2s 1s infinite;
}

.triangle2, .triangle3{
    content: '';
    top: 20px; left: 30px;
    -moz-animation-delay: 1.1s;
    -webkit-animation-delay: 1.1s;
    -ms-animation-delay: 1.1s;
    animation-delay: 1.1s;
}

.triangle3{
    left: -10px;
    -moz-animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
    -ms-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

@-moz-keyframes rotation
{
    0% {-moz-transform: rotate(0deg);}
    20% {-moz-transform: rotate(360deg);}
    100% {-moz-transform: rotate(360deg);}
}

@-webkit-keyframes rotation
{
    0% {-webkit-transform: rotate(0deg);}
    20% {-webkit-transform: rotate(360deg);}
    100% {-webkit-transform: rotate(360deg);}
}

@-ms-keyframes rotation
{
    0% {-webkit-transform: rotate(0deg);}
    20% {-webkit-transform: rotate(360deg);}
    100% {-webkit-transform: rotate(360deg);}
}		

@keyframes rotation
{
    0% {transform: rotate(0deg);}
    20% {transform: rotate(360deg);}
    100% {transform: rotate(360deg);}
}          

@-moz-keyframes fadecolor
{
    0% {border-bottom-color: #eee;}
    100%{border-bottom-color: #67cbf0;}
}

@-webkit-keyframes fadecolor
{
    0% {border-bottom-color: #eee;}
    100%{border-bottom-color: #67cbf0;}
}

@-ms-keyframes fadecolor
{
    0% {border-bottom-color: #eee;}
    100%{border-bottom-color: #67cbf0;}
}		

@keyframes fadecolor
{
    0% {border-bottom-color: #eee;}
    100%{border-bottom-color: #67cbf0;}
}
            

</style>

<template>
    <div class="input float range">

        <label>{{ name }}</label>

        <div class="wrap">

            <div class="wrap">
                <input type='text' @input="test($event.target)" :value="model" :placeholder="placeholder" @keyup.enter="event" :readonly="readonly"/>
                <div class="length">0% — {{ max }}%</div>
            </div>

            <input v-if="!readonly" type="range" min="0" :max="max" @input="test($event.target)" :value="model" step="1"> 

        </div>

    </div>
</template>

<script>

export default {
    
    props: {
        name: String,
        model: Number,
        max: Number,
        placeholder: String,

        event: {
            type: Function,
            default: () => {},
        },

        readonly: {
            type: Boolean,
            default: false,
        },
    },

    emits: ["update:model"],

	setup(props, ctx){

        async function test(target){

            if(props.readonly) return

            let list = target.value.split('%')
            if(list.length < 1) {
                target.value = 0 + '%'; return
            }

            let result = parseInt(list.join(""), 10)
            if(isNaN(result) || result < 0.0){
                result = 0.0
            }

            if(result > props.max){
                result = props.max
            }

            target.value = result + '%'

            ctx.emit('update:model', result)

            await props.event()

        }

        return {
            test,
        }
    }
}

</script>

<style scoped>

.input.float.range {
    position: relative;
    margin-bottom: 1.25em;
    border-radius: 5px;
}

.input.float.range > label {
    left: 0.8em;
    right: auto;
    position: absolute;
    background: white;
    color: #0944AA;
    font-size: .85em;
    top: -0.6em;
    padding: 0 0.35em;
    z-index: 1;
}

.input.float.range> .wrap {
    border: 1px solid #0944AA;
    border-radius: 8px;
    padding: .8em 0 0;
    position: relative;
}

.input.float.range > .wrap > input[type="range"] {
    width: 100%;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: -5px;
}

.input.float.range > .wrap > .wrap {
    margin: 0 1.25em .95em;
    display: flex;
    align-items: center;
}

.input.float.range > .wrap > .wrap > input[type="text"] {
    max-width: 100%;
    margin: 0;
    border: none;
    outline: none;
    width: 100%;
    font-size: .9em;
}

.input.float.range > .wrap > .wrap > .length {
    font-weight: 400;
    font-size: .9em;
    color: #0944AA;
    opacity: 0.5;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.input.float.range:last-of-type {
    margin-bottom: 0;
}

</style>

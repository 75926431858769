<template>
    <div class="wrap">
    
        <svg v-if="i == 0 && pagination.start > 0" @click="left()" class="arrow left" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"></path></svg>

        <div @click="qsort(col.name)" class="name">
            {{ get_lang_name(alang, col.alt_name) }}
        </div>

        <svg v-if="active_tab.id == 1 && col.name == 'Рейтинг компании'" @click="open_pop_up()" class="question" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28zm7.67-24h-16c-6.627 0-12-5.373-12-12v-.381c0-70.343 77.44-63.619 77.44-107.408 0-20.016-17.761-40.211-57.44-40.211-29.144 0-44.265 9.649-59.211 28.692-3.908 4.98-11.054 5.995-16.248 2.376l-13.134-9.15c-5.625-3.919-6.86-11.771-2.645-17.177C185.658 133.514 210.842 116 255.67 116c52.32 0 97.44 29.751 97.44 80.211 0 67.414-77.44 63.849-77.44 107.408V304c0 6.627-5.373 12-12 12zM256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8z" class=""></path></svg>

        <div v-if="active_tab.id == 1 && col.name == 'Рейтинг компании' && pop_up" @click="close_pop_up()" class="pop-up">

            {{ alang.trans_373 }}

            <br>

            {{ alang.trans_374 }}

        </div>

        <svg v-if="cols.length != pagination.stop && pagination.stop < cols.length && i == 0" @click="right()" class="arrow right" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"></path></svg>

    </div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'
import { format_number, get_lang_name } from "@/api/funcs";

export default {

    props: {
        i: Number,
        col: Object,
    },

	setup(){

        const store         = useStore()
        const alang         = computed(() => store.getters.lang)
        const pop_up        = computed(() => store.getters.col_pop_up)
        const active_tab    = computed(() => store.getters.active_tab)
        const cols          = computed(() => store.getters.cols)
        const pagination    = computed(() => store.getters.pagination)

        function close_pop_up(){
            store.dispatch('update_pop_up', false)
        }

        function open_pop_up(){
            store.dispatch('update_pop_up', true)
        }

        function left(){
            store.dispatch('update_left_pagination')
        }

        function right(){
            store.dispatch('update_right_pagination')
        }

        function qsort(name){
            store.dispatch('qsort_tab_historys', name)
        }

		return {

            alang,
            pop_up,
            active_tab,
            cols,
            pagination,

            get_lang_name,
            open_pop_up,
            close_pop_up,
            format_number,
            left, 
            right,
            qsort,
            
		}

	}
}
</script>

export const domain                 = state => state.domain

export const err                    = state => state.err
export const global_block_window    = state => state.global_block_window
export const pop_up                 = state => state.pop_up

export const fast_credit            = state => state.fast_credit
export const chart                  = state => state.chart
export const rules                  = state => state.rules


export const forecasts              = state => state.forecasts
export const where_is               = state => state.where_is

export const time                   = state => state.time









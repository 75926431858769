<template>
	<div class="cabinet">

		<div class="left">

			<img v-if="template.alt_name == 'mangold-new-year'" class="mangold-new-year-v2-mishyra-7" src="https://res.cloudinary.com/shop-consoles-ru/image/upload/v1636809524/bsgames/christmas_garland_PNG46_1_sxzdz4.png">

			<div class="name">{{ active_lang.trans_12 }}</div>
			<div class="desc">{{ active_lang.trans_13 }}</div>

			<div class="wrap">
				<img v-if="template" :src="`${domain}/static/img/main_bottom_icon_4_${template.alt_name}.png`" />
				<div class="right">

					<router-link v-for="game in games" :key="game.id" :to="{ name: `games`, params: { id: game.id, },}" class="wrap">

						<span v-html="game.poster"></span>
						
						<div class="name">{{ game.name }}</div>
					</router-link>

					<div v-if="games != undefined && !games.length">{{ active_lang.trans_14 }}</div>

				</div>
			</div>

		</div>
  
		<div class="right">

			<div class="window">

				<div class="top">
					<div class="circle"></div>
					<div class="circle"></div>
					<div class="circle"></div>
				</div>

				<div class="bottom">
					<div class="wrap">

						<div class="name">
							{{ active_lang.trans_11 }}
						</div>


					</div>
				</div>

			</div>

			<img v-if="template.name != 'mangold-new-year'" :src="`${domain}/static/img/main_header_icon_2.png`"/>
			<img v-else :src="`${domain}/static/img/main_header_icon_2_ne_v2.png`"/>

		</div>

	</div>
  </template>

<script>

import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {

	setup(){

		const router		= useRouter()
		const store 		= useStore()
		const domain 		= computed(() => store.getters.domain)
		const user 			= computed(() => store.getters.user)
		const is_auth		= computed(() => store.getters.is_auth)
		const active_lang 	= computed(() => store.getters.lang)
		const template      = computed(() => store.getters.template)
		const games         = computed(() => store.getters.games)


		onMounted(async () => {

			if(is_auth.value) {

				await store.dispatch('get_me_games')

				store.dispatch('update_where_is', 'cabinet')
				
				return

			}
	
			router.push(`/`)


			
		})

		return {
			domain,
			user,
			is_auth,
			active_lang,
			template,
			games,
		}

	}
}
</script>

import { requests } from "@/api/funcs";





export const update_history = async ({ commit }, objects) => {
    commit('update_history', objects)
}



export const get_history_game = async ({ commit,  dispatch, getters }, object) => {
    
    const [ err, code, body ] =  await requests(`${getters.domain}/api/games/${object.id}/steps/${object.sid}/history`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('update_history', body)

    dispatch('update_tabs', body.tabs)

    dispatch('update_cols')

    for(let tab of body.tabs){
        if(tab.active){
            commit('update_tab_historys', tab.histories)
        }
    }

}



//обновляем историю таба
export const update_tab_historys = async ({ commit }, objects) => {
    commit('update_tab_historys', objects)
}






export const get_me_game = async ({ commit,  dispatch, getters }, id) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/games/${id}/me`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_game', body)

    dispatch('update_tabs', body.tabs)

    dispatch('update_cols')

    for(let tab of body.tabs){
        if(tab.active){
            commit('update_tab_historys', tab.histories)
        }
    }

}

export const clean_game = async ({ commit }) => {
    commit('clean_game')
}

export const update_games = async ({ dispatch, getters }, object) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/games/${object.id}`, 'PUT', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const add_games = async ({ dispatch, getters }, object) => {

    //object.user_id = getters.user.id

    const [ err, code ] =  await requests(`${getters.domain}/api/games`, 'POST', getters.cookie, object)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const delete_games = async ({ dispatch, getters }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/games/${id}`, 'DELETE', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const get_game = async ({ commit,  dispatch, getters }, id) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/games/${id}`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_game', body)
}


export const get_me_games = async ({ commit, dispatch, getters }) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/games/me`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_games', body)
}


export const get_operator_games = async ({ commit, dispatch, getters }) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/games/operator`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_games', body)
}

export const init_game = async ({ dispatch, getters }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/games/${id}/init`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const start_game = async ({ dispatch, getters }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/games/${id}/start`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}

export const stop_game = async ({ dispatch, getters }, id) => {

    const [ err, code ] =  await requests(`${getters.domain}/api/games/${id}/stop`, 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

}





//?fields=id,name,poster
export const get_games = async ({ commit, dispatch, getters }, object = { w_is: 'management', fields: false }) => {

    const [ err, code, body ] =  await requests(`${getters.domain}/api/where-is/${object.w_is}/games/` + (object.fields ? `?fields=${object.fields}` : ``), 'GET', getters.cookie)

    if(err){
        dispatch('update_err_by_code', code); return
    }

    commit('get_games', body)
}

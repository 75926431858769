<template>

    <div class="bottom">

        <div v-for="team in teams" :key="team.id" class="team">

            <!-- team -->
            <team :team="team" :event="is_open_users"></team>

            <!-- user -->
            <div v-if="team.is_open">

                <users :users="team.users"></users>

                <div class="wrap">
                    <div @click="user_add_pop_up_open(team.id)" class="btn blue">
                        {{ lang.trans_439 }}
                    </div>

                    <div @click="open_pop_up_for_delete_teams(team)" class="btn red">
                        {{ lang.trans_440 }}
                    </div>
                </div>


            </div>


        </div>

        <users-add v-if="user_add_pop_up.is" :tid="user_add_pop_up.tid" :close="user_add_pop_up.close"></users-add>

        <pop-up v-if="pop_up.active" :name="pop_up.name" :desc="pop_up.desc" :ok="pop_up.ok" :close="pop_up.close"></pop-up>
        
    </div>

</template>

<script>

import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'


import PopUp  from '@/components/PopUp.vue'
import Team from "@/components/operator/teams/team/Team.vue";
import Users from "@/components/operator/teams/users/Users.vue"
import UsersAdd from "@/components/operator/teams/UsersAdd.vue";

export default {

    components: {
        'pop-up': PopUp,
        'team': Team,
        'users': Users,
        'users-add': UsersAdd,
    },

    setup(){

        const route     = useRoute()
        const store     = useStore()
        const lang      = computed(() => store.getters.lang)
        const teams      = computed(() => store.getters.teams)


        function user_add_pop_up_open(id){

            user_add_pop_up.value.tid = id
            user_add_pop_up.value.is = true
            
        }

        function user_add_pop_up_close(){

            user_add_pop_up.value.tid = null
            user_add_pop_up.value.is = false

        }

        //обьект для компонента
        const user_add_pop_up  = ref({
            is: false,
            tid: null,
            close: () => user_add_pop_up_close(),
        })

        //обьект для компонента
        const pop_up  = ref({
            id: 0,
            name: '',
            desc: '',
            ok: null,
            close: () => pop_up.value.active = false,
            active: false,
        })

         //Функция для иницыализации и открытия компонента
        function open_pop_up_for_delete_teams(team) {

            pop_up.value.id     = team.id
            pop_up.value.name   = lang.value.trans_440
            pop_up.value.desc   = `${team.name} ${lang.value.trans_445}`

            pop_up.value.ok     = async () => {

                await store.dispatch('delete_teams', team.id)

                await store.dispatch('get_operator_teams', route.params.id)

                pop_up.value.active = false
            }

            pop_up.value.active = true

        }

        function is_open_users(team){
            team.is_open = !team.is_open
        }

        return {
            teams,
            lang,
            user_add_pop_up,
            user_add_pop_up_open,
            is_open_users,
            pop_up,
            open_pop_up_for_delete_teams,
        }

    }
}

</script>

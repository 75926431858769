<template>

    <form>
        
        <div v-if="game.tact" class="center">

            <!-- снабжение -->
            <Counter v-if="game.tact.type == `Снабжение`"
                :name="get_lang_name(lang, 270)"
                v-model:model="team.purchase_count"
                :current_value="game.team.purchase_count"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.tact.type == `Снабжение`"
                :name="get_lang_name(lang, 271)"
                v-model:model="team.purchase_price"
                :current_value="game.team.purchase_price"
                :decision="game.team.decision"
            ></Counter>

            <!-- Производство -->
            <Counter v-if="game.tact.type == `Производство`"
                :name="get_lang_name(lang, 272)"
                v-model:model="team.prod_ordinary"
                :current_value="game.team.prod_ordinary"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.tact.type == `Производство`"
                :name="get_lang_name(lang, 273)"
                v-model:model="team.prod_auto"
                :current_value="game.team.prod_auto"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.game.variant && game.tact.type == `Производство`"
                :name="get_lang_name(lang, 274)"
                v-model:model="team.prod_premium_ordinary"
                :current_value="game.team.prod_premium_ordinary"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.game.variant && game.tact.type == `Производство`"
                :name="get_lang_name(lang, 275)"
                v-model:model="team.prod_premium_auto"
                :current_value="game.team.prod_premium_auto"
                :decision="game.team.decision"
            ></Counter>

            <!-- продукция -->
            <Counter v-if="game.tact.type == `Продажи`"
                :name="get_lang_name(lang, 276)"
                v-model:model="team.sales_count"
                :current_value="game.team.sales_count"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.tact.type == `Продажи`"
                :name="get_lang_name(lang, 277)"
                v-model:model="team.sales_price"
                :current_value="game.team.sales_price"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.game.variant && game.tact.type == `Продажи`"
                :name="get_lang_name(lang, 278)"
                v-model:model="team.sales_count_premium"
                :current_value="game.team.sales_count_premium"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.game.variant && game.tact.type == `Продажи`"
                :name="get_lang_name(lang, 279)"
                v-model:model="team.sales_price_premium"
                :current_value="game.team.sales_price_premium"
                :decision="game.team.decision"
            ></Counter>

            <!-- Инвестиции -->
            <Counter v-if="game.tact.type == `Инвестиции`"
                :name="get_lang_name(lang, 280)"
                v-model:model="team.sell_ordinary"
                :current_value="game.team.sell_ordinary"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.tact.type == `Инвестиции`"
                :name="get_lang_name(lang, 281)"
                v-model:model="team.sell_auto"
                :current_value="game.team.sell_auto"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.tact.type == `Инвестиции`"
                :name="get_lang_name(lang, 282)"
                v-model:model="team.build_ordinary"
                :current_value="game.team.build_ordinary"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.tact.type == `Инвестиции`"
                :name="get_lang_name(lang, 283)"
                v-model:model="team.build_auto"
                :current_value="game.team.build_auto"
                :decision="game.team.decision"
            ></Counter>

            <!-- Кредит -->
            <Counter v-if="game.tact.type == `Кредит`"
                :name="get_lang_name(lang, 284)"
                v-model:model="team.loan_take"
                :current_value="game.team.loan_take"
                :decision="game.team.decision"
            ></Counter>
            <Counter v-if="game.tact.type == `Кредит`"
                :name="get_lang_name(lang, 153)"
                v-model:model="team.loan_repay"
                :current_value="game.team.loan_repay"
                :decision="game.team.decision"
            ></Counter>

        </div>
        
        <div @click="update()" class="button">

            {{ button_name }}

            <img v-if="template.name == 'mangold-new-year'" class="mangold-new-year-v2-mishyra-5" src="https://res.cloudinary.com/shop-consoles-ru/image/upload/v1636790067/bsgames/santa_claus_PNG9989_2_cxtyvb.png">
        </div>

    </form>


</template>

<script>

import { computed, onMounted, watch, ref } from 'vue'
import { useStore } from 'vuex'

import { get_lang_name, requests } from "@/api/funcs";

import Counter from '@/components/game/Counter.vue';

export default {
    components: {
        Counter
    },

    setup(){

        const store     = useStore()
        const domain    = computed(() => store.getters.domain)
        const cookie    = computed(() => store.getters.cookie)
        const lang      = computed(() => store.getters.lang)
        const template  = computed(() => store.getters.template)
        const user      = computed(() => store.getters.user)
        const game      = computed(() => store.getters.game)


        const team = ref({
            purchase_count: 0,
            purchase_price: 0,
            prod_ordinary: 0,
            prod_auto: 0,
            prod_premium_ordinary: 0,
            prod_premium_auto: 0,
            sales_count: 0,
            sales_price: 0,
            sales_count_premium: 0,
            sales_price_premium: 0,
            sell_ordinary: 0,
            sell_auto: 0,
            build_ordinary: 0,
            build_auto: 0,
            loan_take: 0,
            loan_repay: 0,
        })

        const button_name = ref('')

        onMounted(() => {

            button_name.value = lang.value.trans_38

            team.value = {
                id:                     game.value.team.id,
                purchase_count:         game.value.team.purchase_count,
                purchase_price:         game.value.team.purchase_price,
                prod_ordinary:          game.value.team.prod_ordinary,
                prod_auto:              game.value.team.prod_auto,
                prod_premium_ordinary:  game.value.team.prod_premium_ordinary,
                prod_premium_auto:      game.value.team.prod_premium_auto,
                sales_count:            game.value.team.sales_count,
                sales_price:            game.value.team.sales_price,
                sales_count_premium:    game.value.team.sales_count_premium,
                sales_price_premium:    game.value.team.sales_price_premium,
                sell_ordinary:          game.value.team.sell_ordinary,
                sell_auto:              game.value.team.sell_auto,
                build_ordinary:         game.value.team.build_ordinary,
                build_auto:             game.value.team.build_auto,
                loan_take:              game.value.team.loan_take,
                loan_repay:             game.value.team.loan_repay,
            }
        })

        watch(game, () => {

            button_name.value = lang.value.trans_38

            team.value = {
                id:                     game.value.team.id,
                purchase_count:         game.value.team.purchase_count,
                purchase_price:         game.value.team.purchase_price,
                prod_ordinary:          game.value.team.prod_ordinary,
                prod_auto:              game.value.team.prod_auto,
                prod_premium_ordinary:  game.value.team.prod_premium_ordinary,
                prod_premium_auto:      game.value.team.prod_premium_auto,
                sales_count:            game.value.team.sales_count,
                sales_price:            game.value.team.sales_price,
                sales_count_premium:    game.value.team.sales_count_premium,
                sales_price_premium:    game.value.team.sales_price_premium,
                sell_ordinary:          game.value.team.sell_ordinary,
                sell_auto:              game.value.team.sell_auto,
                build_ordinary:         game.value.team.build_ordinary,
                build_auto:             game.value.team.build_auto,
                loan_take:              game.value.team.loan_take,
                loan_repay:             game.value.team.loan_repay,
            }

        })

        watch(lang, () => {
            button_name.value = lang.value.trans_38
        })


        async function update(){

            if(game.value.tact && game.value.tact.status != "Активен"){
                store.dispatch('update_err_by_code', 112); return
            }

            store.dispatch('global_block_window', true) 


            const [ err, code ] = await requests(`${domain.value}/api/teams/games/${game.value.game.id}/decision`, 'PUT', cookie.value, team.value)
            if(err){
                await store.dispatch('update_err_by_code', code); store.dispatch('global_block_window', false); return
            }

            await store.dispatch('get_me_game', game.value.game.id)


            //отправляем команду оператору на обновление списка команд
            await store.dispatch('socket_send', {
                command: "operator_update_decision", 
                gid: game.value.game.id,
            })

            store.dispatch('global_block_window', false) 

            button_name.value = lang.value.trans_39
            setTimeout(() => {
                button_name.value = lang.value.trans_38
            }, 1000)


            await store.dispatch('add_operations', { profile: user.value.id, name: `Заявка на ${game.value.tact.type} успешно сохранена!` });

        }

        return {
            lang,
            get_lang_name,
            template,
            game,
            team,

            button_name,
            update,
        }
    }
}
</script>

import { ref, computed, onUnmounted } from 'vue';
import { trackWindowWidth } from "@/api/trackWindowWidth"


export function usePagination() {

    // массив, представляющий столбцы в таблице. 
    const cols = ref([])
    
    // вычисляемая реактивная переменная — получает подмножество массива 'cols' на основе значения пагинации.
    const cols_filter = computed(() => cols.value.slice(pagination.value.start, pagination.value.stop))


    const unsubscribe = trackWindowWidth(width => {
        calculateVisibleColumns(width)
    });

    onUnmounted(() => unsubscribe())

    const pagination = ref({
        start: 0, 
        stop: 0,
    })

    // Функция для определения количества видимых столбцов на основе ширины окна.
    const calculateVisibleColumnsCount = (windowWidth) => {

        // Определение "точек останова" для различных ширин экрана.
        const screenBreakpoints = [500, 650, 800, 950, 1100, 1250, 1350, 1440];

        // Нахождение первой точки останова, которая больше текущей ширины окна.
        let breakpointIndex = screenBreakpoints.findIndex(bp => windowWidth <= bp);

        // Возвращаем количество видимых столбцов.
        // Если ширина экрана больше последней точки останова, количество столбцов равно длине массива screenBreakpoints + 1.
        // В противном случае, количество столбцов равно индексу точки останова + 2 (так как индексация начинается с 0).
        return breakpointIndex === -1 ? screenBreakpoints.length + 1 : breakpointIndex + 2;
    }

    const calculateVisibleColumns = (width) => {

        let visibleColumnsCount = calculateVisibleColumnsCount(width);

        // Установка количества видимых столбцов в состоянии пагинации.
        pagination.value.stop = visibleColumnsCount;

    }

    const updateActiveCols = () => {
        if (cols.value) {
            cols.value.forEach((col, index) => {
                col.active = (index >= pagination.value.start && index < pagination.value.stop);
            })
        }
    }

    const left_cols = () => {
        if (cols.value) {
            if(pagination.value.start > 0){
                pagination.value.start -= 1
                pagination.value.stop -= 1
            }

            updateActiveCols();
        }
    }

    const right_cols = () => {
        if (cols.value) {
            if(pagination.value.stop <= cols.value.length){
                pagination.value.start += 1
                pagination.value.stop += 1
            }

            updateActiveCols();
        }
    }

    return { 
        pagination, 
        updateActiveCols, 
        left_cols, 
        right_cols, 
        calculateVisibleColumns,
        cols,
        cols_filter,
    };
}

<template>

    <div class="current tact">

        <div class="top">

            <div v-if="game.status != `Не активна`" class="step">{{ step.step_number }}</div>

            <div v-if="tact" class="wrap">
                <div v-if="game.status != `Не активна`" class="icon" v-html="tact.icon"></div>
                <div class="name">{{ get_tact_name(tact.type) }}</div>
            </div>

            <div v-if="game.status != `Не активна`" class="mode">
                <input-bool name="AUTO" v-model:model="game.auto" :event="update_games"></input-bool>
            </div>

        </div>

        <div v-if="game.status == 'Активна'" class="center">

            <div class="timer">
                {{ game.is_pause ? game.time_pause_format : time }}
            </div>

            <div class="application">

                <div class="name">
                    {{ game.teams.filter((o) => o.decision).length }}/{{ game.teams.length }}
                </div>

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 4H18C18.5304 4 19.0391 4.21071 19.4142 4.58579C19.7893 4.96086 20 5.46957 20 6V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H8" stroke="#5A6671" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 2H9C8.73478 2 8.48043 2.10536 8.29289 2.29289C8.10536 2.48043 8 2.73478 8 3V5C8 5.26522 8.10536 5.51957 8.29289 5.70711C8.48043 5.89464 8.73478 6 9 6H15C15.2652 6 15.5196 5.89464 15.7071 5.70711C15.8946 5.51957 16 5.26522 16 5V3C16 2.73478 15.8946 2.48043 15.7071 2.29289C15.5196 2.10536 15.2652 2 15 2V2Z" stroke="#5A6671" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 13L11 15L15 11" stroke="#5A6671" stroke-opacity="0.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

            </div>

        </div>

        <div v-else-if="game.status != `Не активна`" class="center">
            <div class="name">
                {{ get_lang_status(game.status) }}
            </div>
        </div>


        <div class="bottom">

            <div @click="select_func" :class="[`btn`, get_status()]">
                {{ get_btn_name() }}
            </div>


            <div v-if="game.status == 'Активна' && !game.is_pause && (tact && tact.status == 'Активен')" @click="lock_game" class="pause">

                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.0002 28.7143V5.85714C12.0002 4.27919 10.721 3 9.14303 3C7.56508 3 6.28589 4.27919 6.28589 5.85714V28.7143C6.28589 30.2922 7.56508 31.5714 9.14303 31.5714C10.721 31.5714 12.0002 30.2922 12.0002 28.7143Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M29.143 28.7143V5.85714C29.143 4.27919 27.8638 3 26.2859 3C24.7079 3 23.4287 4.27919 23.4287 5.85715V28.7143C23.4287 30.2922 24.7079 31.5714 26.2859 31.5714C27.8638 31.5714 29.143 30.2922 29.143 28.7143Z" fill="white" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

            </div>
            
            <div v-if="game.status == 'Активна' && game.is_pause && (tact && tact.status == 'Активен')" @click="unlock_game" class="pause">
                плей
            </div>

            <div v-if="game.status == 'Завершена'" @click="open_pop_up" class="pause refresh">

                <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0133 12.9769C14.1599 7.05495 22.3221 5.61573 28.2441 9.76234C30.8685 11.5999 32.612 14.2269 33.3799 17.097L29.1654 18.2264C28.6536 16.3126 27.4911 14.5614 25.7415 13.3364C21.7934 10.5718 16.3519 11.5313 13.5873 15.4795L16.0918 17.2331L8.36041 19.1403L7.5083 11.2229L10.0133 12.9769Z" fill="currentColor"></path>
                    <path d="M15.7304 27.6336C19.6786 30.3981 25.1201 29.4386 27.8846 25.4904L25.3801 23.7368L33.1115 21.8296L33.9636 29.747L31.4587 27.993C27.312 33.915 19.1498 35.3542 13.2278 31.2076C10.6035 29.37 8.8599 26.7431 8.09204 23.8729L12.3065 22.7435C12.8183 24.6574 13.9808 26.4085 15.7304 27.6336Z" fill="currentColor"></path>
                </svg>

            </div>

            <pop-up v-if="pop_up.active" :name="pop_up.name" :desc="pop_up.desc" :ok="pop_up.ok" :close="pop_up.close"></pop-up>

        </div>

    </div>
</template>

<script>

import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import InputBool  from '@/components/form/InputBool.vue'
import PopUp  from '@/components/PopUp.vue'

import { get_lang_name }  from '@/api/funcs'


export default {

    components: {
        'pop-up': PopUp,
        "input-bool": InputBool,
    },

    setup(){

        const route             = useRoute()
        const store             = useStore()
        //const err               = computed(() => store.getters.err)
        const lang              = computed(() => store.getters.lang)
        //const user              = computed(() => store.getters.user)
        const game              = computed(() => store.getters.game)
        const time              = computed(() => store.getters.time)
        const step              = computed(() => store.getters.step)
        const tact              = computed(() => store.getters.tact)

        const pop_up  = ref({
            id: 0,
            name: '',
            desc: '',
            ok: null,
            close: () => pop_up.value.active = false,
            active: false,
        })

        //окошко инициализации игры
        function open_pop_up() {

            pop_up.value.name   = lang.value.trans_456
            pop_up.value.desc   = lang.value.trans_457

            pop_up.value.ok     = async () => {
                await store.dispatch('global_block_window', true)

                await init_game(); pop_up.value.active = false

                await unlock()

                await store.dispatch('global_block_window', false)
            }

            pop_up.value.active = true

        }

        watch(time, async () => {

            if(game.value.auto && time.value == `00:00:00`){

                if(tact.value.type == `Кредит`){
                    await select_func(); setTimeout(async () => await select_func(), 1000); setTimeout(async () => await select_func(), 2000)
                }else{
                    await select_func(); setTimeout(async () => await select_func(), 1000)
                }

            }

        })

        function get_lang_status(status) {
            switch(status){
                case 'Не активна':  return get_lang_name(lang.value, 412)
                case 'Активна':     return get_lang_name(lang.value, 413)
                case 'Завершена':   return get_lang_name(lang.value, 414)
            }
        }

        function get_tact_name(type){

            if(game.value.status == `Не активна`){
                return get_lang_status(game.value.status)
            }

            switch(type){
                case "Снабжение":       return get_lang_name(lang.value, 96)
                case "Производство":    return get_lang_name(lang.value, 97)
                case "Продажи":         return get_lang_name(lang.value, 98)
                case "Издержки":        return get_lang_name(lang.value, 209)
                case "Инвестиции":      return get_lang_name(lang.value, 258)
                case "Кредит":          return get_lang_name(lang.value, 22)
            }
        }

        async function start_tact(tact) {

            await store.dispatch('start_tact', tact.id)

            //стартуем таймет
            await store.dispatch('socket_send', {
                command: "unlock",
                gid: parseInt(route.params.id, 10)
            })

        }

        //разблокируем таймер
        async function unlock_game() {

            game.value.is_pause = false

            game.value.time_pause_format = `00:00:00`

            //обновляем статус паузы игры
            await store.dispatch('update_games', game.value)

            //обновляем данные на странице с игрой, у пользователей
            await store.dispatch('socket_send', {
                command: "unlock",
                gid: parseInt(route.params.id, 10)
            })

            await unlock()

        }

        //блокируем таймер
        async function lock_game() {
            
            game.value.is_pause = true

            game.value.time_pause_format = time.value

            //обновляем статус паузы игры
            await store.dispatch('update_games', game.value)

            //обновляем данные на странице с игрой, у пользователей
            await store.dispatch('socket_send', {
                command: "lock",
                gid: parseInt(route.params.id, 10)
            })

            await unlock()

        }

        async function init_game() {

            await store.dispatch('init_game', game.value.id)

            await store.dispatch('get_game', game.value.id)

        }

        async function start_game() {

            await store.dispatch('start_game', game.value.id)

            await store.dispatch('get_game', game.value.id)

        }

        async function unlock(){

            //обновляем данные c игрой
            await store.dispatch('socket_send', {
                command: "update_game",
                gid: parseInt(route.params.id, 10)
            })

        }

        async function select_func(){


            let debug = ""

            await store.dispatch('global_block_window', true)

            if(game.value.status == 'Не активна'){
                debug = "init_game, start_game "
                await init_game(); await start_game(); await unlock(); await store.dispatch('get_game', route.params.id); store.dispatch('global_block_window', false); return
                
            }

            if(game.value.status == 'Завершена'){
                debug += "start game "
                await start_game(); await unlock(); await store.dispatch('get_game', route.params.id); store.dispatch('global_block_window', false); return
            }


            if(tact.value){

                if(tact.value.type == `Кредит`){


                    if(tact.value.status == 'Не начат'){
                        debug += "start_tact "
                        await start_tact(tact.value); await unlock(); await store.dispatch('get_game', route.params.id); store.dispatch('global_block_window', false); return
                    }


                    if(tact.value.status == 'Активен'){
                        debug += "stop_tact "
                        await stop_tact(tact.value); await unlock(); await store.dispatch('get_game', route.params.id); store.dispatch('global_block_window', false); return
                    }

                    if(tact.value.status == 'Завершен'){
                        debug += "step_finish "
                        await step_finish(); await unlock(); await store.dispatch('get_game', route.params.id); store.dispatch('global_block_window', false); return
                    }


                }else{

                    if(tact.value.status == 'Не начат'){
                        debug += "start_tact "
                        
                        console.log(debug)
                        await start_tact(tact.value); await unlock(); await store.dispatch('get_game', route.params.id); store.dispatch('global_block_window', false); return

                    }else{

                        debug += "stop_tact "
                        console.log(debug)
                        await stop_tact(tact.value); await unlock(); await store.dispatch('get_game', route.params.id); store.dispatch('global_block_window', false); return

     
                    }

                }

            }

            console.log(debug)

        }

        function get_btn_name(){

            if(game.value.status == 'Не активна'){
                return lang.value.trans_417 //`Начать игру`
            }

            if(game.value.status == 'Завершена'){
                return lang.value.trans_418 //`Возобновить игру`
            }


            if(tact.value){

                if(tact.value.type == `Кредит`){
                    
                    if(tact.value.status == 'Не начат'){
                        return lang.value.trans_419 //`Начать этап`
                    }
                    
                    if(tact.value.status == 'Активен'){
                        return lang.value.trans_420 //`Завершить этап`
                    }

                    if(tact.value.status == 'Завершен'){
                        return lang.value.trans_177 //`Завершить ход`
                    }

                }else{

                    if(tact.value.status == 'Не начат'){
                        return lang.value.trans_419 //`Начать этап`
                    }else{
                        return lang.value.trans_420 //`Завершить этап`
                    }

                }
                
            }

            return ''
        }

        function get_status() {

            if(tact.value != null){

                switch(tact.value.status){
                    case 'Активен': 
                        return 'active'
                    case 'Завершен': 
                        return 'finished'
                }
            }

            return 'not-active'
            
        }

        async function stop_tact(object) {
            //производим вычисления
            await store.dispatch('stop_tact', object.id)
        }

        async function step_finish() {
            await store.dispatch('step_finish', game.value.id)
        }

        async function update_games(){
            await store.dispatch('update_games', game.value)
        }

        return {

            get_tact_name,
            get_lang_status,
            game,
            unlock_game,
            lock_game,
            update_games,
            init_game,
            pop_up,
            open_pop_up,

            time,
            select_func,
            get_btn_name,
            stop_tact,
            get_status,
            step,
            tact,
        }

    }
}

</script>

<style scoped>

.current.tact {
    padding: 15px 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.current.tact > .top {
    display: flex;
    align-items: center;
}

.current.tact > .top > .step {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
    padding: 0 0.45em;
    border-radius: 30px;
    color: white;
    font-weight: 500;
    font-size: 24px;
}

.current.tact > .top > .wrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 1em;
}

.current.tact > .top > .wrap > .icon {
    display: flex;
    align-items: center;
    color: #0944AA;
}

.current.tact > .top > .wrap > .name {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 24px;
    color: #0944AA;
    margin-left: 5px;
    max-width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media (min-width: 600px) {
    .current.tact > .top > .wrap > .name {
        max-width: 100%;
    }
}

@media (min-width: 1250px) {
    .current.tact > .top > .wrap > .name {
        font-size: 36px;
        margin-left: 15px;
    }
}


.current.tact > .top > .mode {
    display: flex;
    align-items: center;
}

.current.tact > .top > .mode > .input.bool {
    margin-bottom: 0;
}

.current.tact > .top > .mode > .name {
    font-weight: 400;
    font-size: 16px;
    color: #0944AA;
}

@media (min-width: 1250px) {
    .current.tact > .top > .mode > .name {
        font-size: 24px;
    }
}

.current.tact > .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    
}

@media (min-width: 1250px) {
    .current.tact > .center {
        margin-top: 45px;
    }
}

.current.tact > .center > .name {
    font-weight: 500;
    font-size: 24px;
    color: rgba(90, 102, 113, 0.5);
}


.current.tact > .center > .timer {
    font-weight: 500;
    font-size: 48px;
    color: rgba(90, 102, 113, 0.5);
}

@media (min-width: 1250px) {
    .current.tact > .center > .timer {
        font-size: 96px;
    }
}


.current.tact > .center > .application {
    display: flex;
    align-items: center;
}

.current.tact > .center > .application > .name {
    font-weight: 500;
    font-size: 24px;
    color: rgba(90, 102, 113, 0.5);
}

@media (min-width: 1250px) {
    .current.tact > .center > .application > .name {
        font-size: 36px;
    }
}

.current.tact > .bottom {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
}

.current.tact > .bottom > .btn {
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    padding: 13px 0;
    text-align: center;
    flex-grow: 1;
    margin-right: 10px;
    cursor: pointer;
}

@media (min-width: 1250px) {
    .current.tact > .bottom > .btn {
        max-width: 290px;
    }
}

.current.tact > .bottom > .btn.not-active {
    background: linear-gradient(229.73deg, #00A76B -19.09%, #50FFAB 131.11%);
}

.current.tact > .bottom > .btn.active {
    background: linear-gradient(229.73deg, #A70000 -19.09%, #FF5050 131.11%);
}

.current.tact > .bottom > .btn.finished {
    background: grey;
}


.current.tact > .bottom > .pause {
    background: #5A6671;
    opacity: 0.5;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 13px;
    color: white;
    cursor: pointer;
}

</style>
<template>
    <div class="steps">

        <svg @click="click_left" width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.499999 7.86602C-0.166668 7.48112 -0.166667 6.51888 0.5 6.13397L9.5 0.937821C10.1667 0.552921 11 1.03405 11 1.80385L11 12.1962C11 12.966 10.1667 13.4471 9.5 13.0622L0.499999 7.86602Z" fill="url(#paint0_linear_552_450)"/>
            <defs>
            <linearGradient id="paint0_linear_552_450" x1="-4.61929" y1="-3.57648" x2="17.2718" y2="22.2685" gradientUnits="userSpaceOnUse">
            <stop stop-color="#003DA7"/>
            <stop offset="1" stop-color="#50C7FF"/>
            </linearGradient>
            </defs>
        </svg>

        <div class="wrap">

            <div v-for="object in steps_filter" :key="object.id" @click="selected(object)" :class="['step', get_status(object.status), {'selected': object.id == selected_step.id}]">
                {{ object.step_number }}
            </div>

            <div v-if="is_visible_pagination()">
                ...
            </div>

            <div v-if="is_visible_pagination()" class="step not-active ">
                {{ game.steps.length }}
            </div>

        </div>

        <svg @click="click_right" width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 7.86602C11.1667 7.48112 11.1667 6.51888 10.5 6.13397L1.5 0.937821C0.833334 0.552921 6.10471e-07 1.03405 5.76822e-07 1.80385L1.2256e-07 12.1962C8.8911e-08 12.966 0.833333 13.4471 1.5 13.0622L10.5 7.86602Z" fill="url(#paint0_linear_552_106)"/>
            <defs>
            <linearGradient id="paint0_linear_552_106" x1="15.6193" y1="-3.57648" x2="-6.27178" y2="22.2685" gradientUnits="userSpaceOnUse">
            <stop stop-color="#003DA7"/>
            <stop offset="1" stop-color="#50C7FF"/>
            </linearGradient>
            </defs>
        </svg>

    </div>
</template>

<script>

import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'

export default {

    setup(){

        const store = useStore()

        const game              = computed(() => store.getters.game)
        const step              = computed(() => store.getters.step)
        const selected_step     = computed(() => store.getters.selected_step)

        watch(game, () => {

            let i = 0

            for(let step of game.value.steps){

                i++

                if(step.status == 'Активен'){
                    break
                }

            }

            if((i - 3) < 3){
                pagination.value.start = 0
            }else{
                pagination.value.start = i - 3
            }

            if(i < 3){
                pagination.value.stop = 3
            }else{
                pagination.value.stop = i

            }

            
        })

        function is_visible_pagination(){

            if(game.value.steps.length != pagination.value.stop){
                return true
            }

            return false
        }


        const pagination = ref({
            start: 0, 
            stop: 3,
        })

        const steps_filter  = computed(() => game.value.steps.slice(pagination.value.start, pagination.value.stop))

        async function selected(object){

            if(object.status == 'Не начат') return

            await store.dispatch('set_selected_step', object)

        }

        function click_left() {
            if(pagination.value.start > 0){
                pagination.value.start -= 1
                pagination.value.stop -= 1
            }
        }



        function click_right() {
            if(pagination.value.stop < game.value.steps.length){
                pagination.value.start += 1
                pagination.value.stop += 1
            }
        }

        function get_status(status) {
            switch(status){
                case 'Не начат': return 'not-active'
                case 'Активен': return 'active'
                case 'Завершен': return 'finished'
            }
        }


        
        return {
            game,
            step,
            selected_step,

            is_visible_pagination,
            selected,
            click_right,
            click_left,
            get_status,
            steps_filter,
        }

    }
}

</script>

<style scoped>

.steps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5em;
}

.steps > svg {
    cursor: pointer;
}

.steps > .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1em;
}

.steps > .wrap > .step {
    font-weight: 500;
    font-size: 24px;
    color: #0944AA;
    margin: 0 5px;
}

.steps > .wrap > .step {
    padding: .1em .5em;
    border-radius: 30px;
    border: 1px solid white;
}

.steps > .wrap > .step.finished {
    cursor: pointer;
}


.steps > .wrap > .step.active {
    background: linear-gradient(229.73deg, #003DA7 -19.09%, #50C7FF 131.11%);
    color: white;
    cursor: pointer;
}

.steps > .wrap > .step.active.selected {
    cursor: no-drop;
}

.steps > .wrap > .step.not-active {
    color: #c7cacb;
    cursor: no-drop;
}

.steps > .wrap > .step.selected {
    border: 1px solid black;
}

</style>